import React, { lazy, Suspense } from 'react';
// import '../src/assets/css/style.css';
// import '../src/assets/css/animation_style.css';
// import Swiper from 'react-id-swiper';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import functions from '../src/assets/functions/common_functions';
import session from '../src/assets/functions/session';
import cookie from 'react-cookies';
import url from '../src/assets/url';
import LottieLoader from '../LottieLoader';
import { withRouter } from 'next/router';
import Router from 'next/router';
import dynamic from 'next/dynamic';
// import SessionEnter from './session_enter';
// import Programtile from './program_tiles.js';
// import Review from '../review_section/reviewrequest';
// import StoryDetail from '../innerpages/story_detail';
import ContentLoader from 'react-content-loader';
import { TimelineLite, TweenLite, TweenMax, Expo, TimelineMax, Bounce, Circ, Linear, Power1, Power2, Power3, Back, Power4, SlowMo } from "gsap";
// import CSSPlugin from 'gsap/CSSPlugin';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import $ from "jquery";
// import Footer from '../innerpages/footer';
// import PerksDetail from '../perks/perksdetails';
// import PerksDetailAll from '../perks/perkdetailall'
import MyLoader from '../components/ContentLoader.js';
import { connect } from 'react-redux';
import AWS from 'aws-sdk';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
// import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation'; // Import navigation styles

// Import required modules
// import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import { Pagination, Navigation } from 'swiper/modules';

const C = dynamic(() => import('gsap/CSSPlugin'), { ssr: false });


const Cryptr = require('cryptr');
// const C = CSSPlugin;
const cryptr = new Cryptr('Family.one');
import Programtile from '../components/program_tiles.js';

var base64 = require('base-64');
class Homemain extends React.Component {
    constructor(props) {
        super(props);
        this.toggleTab = this.toggleTab.bind(this);
        this.setlocalstoragePrograms = this.setlocalstoragePrograms.bind(this);
        this.state = {
            active: false,
            activeTab: typeof window !== "undefined" && localStorage.getItem("activeTab")
  ? localStorage.getItem("activeTab")
  : 'all',

            main_loader: false,
            is_hydrated: false,
            dynamic_content: "",
            activeIndex: 0,
            all_program: props.all_prog || [],
            perk_array: props.perk_array || [],
            featured_program_array: props.featured_program_array || [],
            activeSlideKey: 0,
            loader: false,
            show_detail: false,
            show_freebie: props.show_freebie || false,
            show_giveaway: props.show_giveaway || false,
            show_survey: props.show_survey || false,
            show_review: props.show_review || false,
            show_story: props.show_story || false,
            perk_and_program_loaded: false,
            auto_id: typeof window !== 'undefined' ? (cookie.load("auto_id") || localStorage.getItem("auto_id")) : null,
            loading: false

        };
        this.goNext = this.goNext.bind(this);
        this.goPrev = this.goPrev.bind(this);
        this.swiper = null
    }

    handleFixedHeader = () => {
        $(window).scroll(function () {
            var navbar = document.getElementById("navbar");
            if (navbar) {
                var sticky = navbar.offsetTop;
                if ($(window).scrollTop() >= (sticky - 39)) {
                    $('.fix_main').addClass('fixed-header');
                }
                else {
                    $('.fix_main').removeClass('fixed-header');
                }
            }
        });
    }

    getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    }

    getDynamicContent = () => {
        let self = this;
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
            self.OnPageLoad(this.props.data)
        }
        else {
            self.OnPageLoad(this.props.data)
        }
    }

    setlocalstoragePrograms = () => {
        setTimeout(() => {
            // this.toggleTab(tab);
            functions.scrollToDiv(localStorage.getItem('program_div'))
        }, 10);
        return
        let program_array_enter = []
        let program_array_is_eligble = []
        let program_array_notenter = []
        let all_program = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
        all_program?.length > 0 && all_program.map((ele) => {
            if (ele.has_enter) {
                program_array_enter.push(ele)
            }
            else {
                if (ele.is_eligible || ele.pending_fields.length)
                    program_array_is_eligble.push(ele);
                else
                    program_array_notenter.push(ele);
            }
        })
        program_array_enter.sort(function (a, b) {
            return a.all_order_number - b.all_order_number
        })
        program_array_notenter.sort(function (a, b) {
            return a.all_order_number - b.all_order_number
        })
        program_array_is_eligble.sort(function (a, b) {
            return a.all_order_number - b.all_order_number
        })
        all_program = [...program_array_is_eligble, ...program_array_notenter, ...program_array_enter]
        let featured_program_array = all_program.filter((e) => e.featured == 1 == true);
        featured_program_array.sort(function (a, b) {
            let aOrder = a.feature_order_number ? a.feature_order_number : 0
            let bOrder = b.feature_order_number ? b.feature_order_number : 0
            return aOrder - bOrder
        })
        let contest_array = all_program.filter((e) => e.program_type == "contest");
        let freebie_array = all_program.filter((e) => e.program_type == "freebie");
        let survey_array = all_program.filter((e) => e.program_type == "survey");
        let stories_array = all_program.filter((e) => e.program_type == "story");
        let review_array = all_program.filter((e) => e.program_type == "review");

        // self.setState({ loader: false })
        let show_freebie = false
        let show_giveaway = false
        let show_survey = false
        let show_review = false
        let show_story = false
        if (freebie_array.length) {
            show_freebie = true
        }
        if (contest_array.length) {
            show_giveaway = true
        }
        if (survey_array.length) {
            show_survey = true
        }
        if (review_array.length) {
            show_review = true
        }
        if (stories_array.length) {
            show_story = true
        }
        let show_all_program = all_program.filter(pro => pro.show_in_all)
        this.setState({ show_freebie, show_giveaway, show_survey, show_review, show_story, all_program: show_all_program, featured_program_array: featured_program_array })

        let perk_response = JSON.parse(localStorage.getItem("perk_response"));
        if (perk_response && perk_response.length > 0) {
            let perk_array = perk_response.sort(function (a, b) {
                return a.order_number - b.order_number
            })
            perk_response.forEach((element, index) => {
                all_program.push(element)
            })
            this.setState({
                perk_array: perk_array,
                all_program
            })
        }
        const tab = localStorage.getItem("activeTab") ? localStorage.getItem("activeTab") : "all"
        setTimeout(() => {
            this.toggleTab(tab);
            functions.scrollToDiv(localStorage.getItem('program_div'))
        }, 10);
        // setTimeout(() => {
        // }, 500);
    }

    handleLogout = (callback) => {
        // let success_action = "User clicked on logout button on setting page"
        // let image = 'logout_button_setting_page.png'
        // functions.logUserActivity(success_action, image);
        let all_local_storage = this.allLocalStorage();
        let api_auth = localStorage.getItem("api_auth")
        let phone_transfer = localStorage.getItem("phone_transfer")
        let email_transfer = localStorage.getItem("email_transfer")

        all_local_storage.forEach((element, index) => {
            for (var key in element) {
                if (key !== "api_auth" && key !== "dynamic_content" && key !== "phone_transfer" && key !== "email_transfer") {
                    localStorage.removeItem(key);
                }

                else {
                }
            }
        });
        let all_cookie = cookie.loadAll();
        let all_cookie_length = Object.keys(all_cookie).length;
        let index = 0;
        this.props.history.push("/")
        if (callback && typeof callback === 'function') {
            callback();
        }
    }

    componentDidMount = () => {

        const images = [
            "/images/all-active-Icon.svg",
            "/images/all-Icon.svg",
            "/images/Freebies-active-Icon.svg",
            "/images/Freebies-Icon.svg",
            "/images/Giveaways-active-Icon.svg",
            "/images/Giveaways-Icon.svg",
            "/images/Surveys-active-Icon.svg",
            "/images/Surveys-Icon.svg",
            "/images/Perks-active-Icon.svg",
            "/images/Perks-Icon.svg",
            "/images/Stories-active-Icon.svg",
            "/images/Stories-Icon.svg"
        ];

        images.forEach((image) => {
            const img = new Image();
            img.src = image;
        });

        const checkMainIndex = localStorage.getItem("CheckMainIndex");
        const previous_state = localStorage.getItem("previous_state");
        const current_state = localStorage.getItem("current_state");
        const auth = localStorage.getItem("auth");
        // if (checkMainIndex && checkMainIndex == "main_index" && (previous_state == 'session_enter' || current_state == 'perks_detail') && current_state != 'perksonlinecode' && current_state != 'perksinstore') {
            this.setlocalstoragePrograms();
            // localStorage.removeItem("CheckMainIndex");
            // return
        // }
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        let self = this;
        localStorage.setItem("current_state", "main_home")
        let pathname = window.location.hash;
        
        // let pathname = '#login_link?client_id=42j44ianhbbdc4h5gtl9cffjvk&username=anshuinvite1@webnexus.in&confirmation_code=undefined&subid=7536121c-7a99-4289-82dd-1a2cfbcd0114'
        // let pathname = '#connect?user_id=7536121c-7a99-4289-82dd-1a2cfbcd0114&relative_id=404cd8c7-badc-4d10-9767-ef1e37265dc3'
        let session_id = cookie.load("session_id") ? cookie.load("session_id") : null
        let session_auto_id = cookie.load("session_auto_id") ? cookie.load("session_auto_id") : null
        let parameters = this.getUrlVars()
        
        if (auth) {
            this.props.router.push('/home/main');
        }

        if (parameters['irclickid']) {
            cookie.save("irclickid", parameters.irclickid, { path: "/" });
        }
        if (parameters.tracking_id && parameters.tracking_id != "" && parameters.tracking_id.length > 0) {
            let contest_id = parameters.contest_id ? parameters.contest_id : "";
            cookie.save("tracking_id", parameters.tracking_id, { path: "/" });
            session.updateSession(session_id, session_auto_id, auto_id, parameters.tracking_id, contest_id)
        }
        const updateSession = (sessionId, state) => {
            return new Promise((resolve, reject) => {
                try {
                    if (sessionId != null) {
                        session.updatePageSession(sessionId, state);
                        resolve(); // Resolve the promise when done
                    } else {
                        reject(new Error("Session ID is null"));
                    }
                } catch (error) {
                    reject(error); // Reject the promise if there's an error
                }
            });
        };
        if (session_id != null) {
            updateSession(session_id, "main_home")
        }
        // 
        if (pathname.length > 0) {
            let path = [];
            let temp_path = pathname.trim();
            path = temp_path.split("?");
            self.setState({ main_loader: true })
            
            let dynamic_content
            let user_signup_process = ""
            let fb_path = temp_path.split("=");
            localStorage.removeItem("fb_post")
            if (fb_path[0] == "#fbaid") {
                self.setState({ main_loader: true })
                let token_fbclid = [];
                let temp_token_fbclid = fb_path[1].trim();
                token_fbclid = temp_token_fbclid.split("_")

                if (token_fbclid && token_fbclid.length == 3) {
                }
                else {
                    let program_id = token_fbclid[3];
                    if (program_id.length > 4) {
                        localStorage.setItem("perks_or_main", "perks")
                        localStorage.setItem("session_entered_id", program_id.toString())
                    } else {
                        localStorage.setItem("session_entered_id", program_id.toString())
                    }
                }
                let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
                auto_id = auto_id ? auto_id : ""
                if (session_id == null) {
                    session.createSession("null", '', function (response) {
                        if (auto_id == null) {
                            functions.pushToRoute(self, "")
                        }
                        else {
                            localStorage.setItem("fb_post", "true")
                            self.getDynamicContent()
                        }
                    })
                }
                else {
                    if (auto_id == null) {
                        functions.pushToRoute(self, "")
                    }
                    else {
                        localStorage.setItem("fb_post", "true")
                        self.getDynamicContent()
                    }
                }
            }

            switch (path[0]) {
                case "#resetpassword":
                    functions.invokeUserEmailVerify(auto_id)
                    dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                    localStorage.clear();
                    localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                    self.setState({ main_loader: true })
                    let token_rp = [];
                    let temp_token = path[1].trim();
                    token_rp = temp_token.split("&")

                    let client_id = [];
                    let temp_client_id = token_rp[0].trim()
                    client_id = temp_client_id.split("=")

                    let username_rp = [];
                    let temp_username_rp = token_rp[1].trim()
                    username_rp = temp_username_rp.split("=")

                    let sub_id_rp = [];
                    let temp_sub_id_rp = token_rp[3].trim()
                    sub_id_rp = temp_sub_id_rp.split("=")
                    cookie.save("username", username_rp[1], { path: "/" });
                    cookie.save("auto_id", sub_id_rp[1], { path: "/" })
                    localStorage.setItem("auto_id", sub_id_rp[1])
                    if (session_id == null) {
                        session.createSession(sub_id_rp[1], '', function (response) {
                            functions.getUserData(self, username_rp[1], '', 'reset');
                            functions.pushToRoute(this, "reset_password")
                        })
                    }
                    else {
                        session.checkSession(sub_id_rp[1], "resetpassword", 'false');
                        functions.getUserData(self, username_rp[1], '', 'reset');
                        functions.pushToRoute(this, "reset_password")
                    }

                    break;
                case "#glink":
                    let user_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
                    user_id = user_id ? user_id : ""
                    if (path[1]) {
                        // let program_id_global = [];
                        let temp_program_id_global = this.getUrlVars(path[1].trim())
                        let program_id_global = temp_program_id_global.p_id
                        // program_id_global = temp_program_id_global.split("=")[1]
                        let ftm_child_time_stamp = temp_program_id_global.child_ts
                        let ftm_home = temp_program_id_global.ftm_home
                        
                        // cookie.save("auto_id", program_id_global[1], { path: "/" })
                        if (ftm_home) {
                            localStorage.setItem("ftm_home", "true")
                        }
                        if (ftm_child_time_stamp) {
                            cookie.save("ftm_child_time_stamp", ftm_child_time_stamp, { path: "/" })
                            localStorage.setItem("ftm_home", "true")
                        }

                        if (program_id_global.length > 4) {
                            localStorage.setItem("perks_or_main", "perks")
                            localStorage.setItem("session_entered_id", program_id_global.toString())
                        } else {
                            localStorage.setItem("program_visible", program_id_global.toString())
                            localStorage.setItem("session_entered_id", program_id_global.toString())
                        }
                        let session_id_1 = cookie.load("session_id") ? cookie.load("session_id") : null
                        if (session_id_1 == null) {
                            session.createSession(user_id, '', function (response) {
                                if (user_id == null) {
                                    functions.pushToRoute(self, "")
                                }
                                else {
                                    localStorage.setItem("fb_post", "true")
                                    self.getDynamicContent()
                                }
                            })
                        }
                        else {
                            if (user_id == null) {
                                functions.pushToRoute(self, "")
                            }
                            else {
                                session.checkSession(user_id, "glink", 'false');
                                localStorage.setItem("fb_post", "true")
                                self.getDynamicContent()
                            }
                        }
                    }
                    else {
                        functions.getLandingPage(this);
                    }
                    // functions.getUserData(self, username_upadd[1], '', 'address');

                    break;
                case "#upadd":
                    dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : ""
                    localStorage.clear();
                    localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                    let token_upadd = [];
                    let temp_token_upadd = path[1].trim();
                    token_upadd = temp_token_upadd.split("&")

                    let username_upadd = [];
                    let temp_username_upadd = token_upadd[0].trim()
                    username_upadd = temp_username_upadd.split("=")


                    let sub_id_upadd = [];
                    let temp_sub_id_upadd = token_upadd[1].trim()
                    sub_id_upadd = temp_sub_id_upadd.split("=")
                    cookie.save("username", username_upadd[1], { path: "/" });
                    cookie.save("auto_id", sub_id_upadd[1], { path: "/" })
                    localStorage.setItem("auto_id", sub_id_upadd[1])
                    localStorage.setItem("upadd", "true");
                    if (session_id == null) {
                        session.createSession(sub_id_upadd[1], '', function (response) {
                            functions.getUserData(self, username_upadd[1], '', 'address');
                        })
                    }
                    else {
                        functions.getUserData(self, username_upadd[1], '', 'address');
                    }
                    break;
                case "#login_link":
                    dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                    // Object.keys(localStorage).forEach(key => !['phone_transfer', 'email_transfer'].includes(key) && localStorage.removeItem(key));


                    localStorage.setItem("user_signup_process", "false")
                    localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                    
                    const { rlid, pom, cam_id, confirmation_code, login_token, sid, subid, show_popup_email, parent_invite_id, option_selected, reminder_email } = parameters
                    functions.invokeUserEmailVerify(subid)
                    if (pom && pom != "") {
                        localStorage.setItem("perks_or_main", pom);
                    }
                    let username1 = parameters.username
                    
                    if (cam_id && cam_id != "") {
                        let camp_login_id = cam_id
                        let date_camp = new Date()
                        date_camp = date_camp.setHours(date_camp.getHours() + 4)
                        
                        cookie.save('camp_id', camp_login_id, { path: '/', expires: new Date(date_camp) });
                    }
                    if (option_selected && reminder_email) {
                        let final_data = {
                            sub_id: subid,
                            option_selected,
                            reminder_email,
                            "action": "update_coupon"
                        }
                        functions.callTommeeReminderMail(final_data)
                    }
                    if (sid && sid != "") {
                        localStorage.setItem('auto_invoke_program', sid)
                        localStorage.setItem("session_entered_id", sid);
                    }

                    let winner_id_rp = cookie.load("winner_id") ? cookie.load("winner_id") : ""

                    if (winner_id_rp != "" && typeof winner_id_rp != undefined) {
                        if (winner_id_rp != subid) {
                            cookie.remove("winner_id", { path: "/" })
                            cookie.remove("winner_program_id", { path: "/" })
                        }
                    }

                    if (show_popup_email && show_popup_email != "") {
                        localStorage.setItem('show_popup_email', show_popup_email);
                    }
                    if (parent_invite_id && parent_invite_id != "") {
                        localStorage.setItem('parent_invite_id', parent_invite_id);
                    }
                    if (rlid && rlid != "") {
                        localStorage.setItem('rlid', rlid);
                    }


                    if (login_token && login_token != "") {
                        functions.ValidateToken(subid, login_token, username1, function (validate_response) {
                            
                            let res_json = JSON.parse(validate_response)
                            if (res_json?.data?.is_valid == 1) {
                                
                                localStorage.setItem("auto_id", subid)
                                cookie.save("auto_id", subid, { path: "/" })
                                cookie.save("username", username1, { path: "/" });
                                if (session_id == null) {
                                    session.createSession(subid, 'login', function (response) {
                                        // session.checkSession(subid, "login", 'false');
                                        let element = document.getElementById("login_complete")
                
                                        element.click();
                                        if (cookie.load("accept_auto_id") && cookie.load("relative_id") && cookie.load("accept_auto_id") == cookie.load("auto_id")) {
                                            functions.addRelative(self, subid, cookie.load("relative_id"), username1);
                                        }
                                        else {
                                            functions.getUserData(self, username1, 'login')
                                        }
                                    })
                                }
                                else {
                                    session.checkSession(subid, "login", 'false');
                                    let element = document.getElementById("login_complete")
                
                                    if (element && element != null) element.click();
                
                                    if (cookie.load("accept_auto_id") && cookie.load("relative_id") && cookie.load("accept_auto_id") == cookie.load("auto_id")) {
                                        functions.addRelative(self, subid, cookie.load("relative_id"), username1);
                                    }
                                    else {
                                        functions.getUserData(self, username1, 'login')
                                    }
                                }
                                functions.getUserData(self, username1, 'login');
                                self.handleContinue(subid, parameters.username)
                            } else {
                                Router.push("/")
                                            // callback("Invalid Verification code")
                            }
                        })
                    } else if (session_id == null) {
                        cookie.save("auto_id", subid, { path: "/" })
                        localStorage.setItem("auto_id", subid)
                        cookie.save("username", username1, { path: "/" });
                        session.createSession(subid, 'login', function (response) {
                            session.checkSession(subid, "login", 'false');
                            let element = document.getElementById("login_complete")
                            
                            element.click();
                            if (cookie.load("accept_auto_id") && cookie.load("relative_id") && cookie.load("accept_auto_id") == cookie.load("auto_id")) {
                                functions.addRelative(self, subid, cookie.load("relative_id"), username1);
                            }
                            else if (rlid) {
                                functions.addRelative(self, subid, rlid, username1);
                            }
                            else {
                                functions.getUserData(self, username1, 'login')
                            }
                            functions.getUserData(self, username1, 'login');

                            self.handleContinue()

                        })
                    } else {
                        cookie.save("auto_id", subid, { path: "/" })
                        localStorage.setItem("auto_id", subid)
                        cookie.save("username", username1, { path: "/" });
                        session.checkSession(subid, "login", 'false');
                        let element = document.getElementById("login_complete")
                        
                        element.click();
                        if (cookie.load("accept_auto_id") && cookie.load("relative_id") && cookie.load("accept_auto_id") == cookie.load("auto_id")) {
                            functions.addRelative(self, subid, cookie.load("relative_id"), username1);
                        }
                        else if (rlid) {
                            functions.addRelative(self, subid, rlid, username1);
                        }
                        else {
                            functions.getUserData(self, username1, 'login')
                        }
                        functions.getUserData(self, username1, 'login');

                        self.handleContinue()

                    }

                    break;
                case "#pv":
                    functions.invokeUserEmailVerify(auto_id)
                    const { phoneOtpEncoded } = parameters;
                    if (phoneOtpEncoded && phoneOtpEncoded.length > 0) {
                        functions.verifySmsLink(this, phoneOtpEncoded, function (response_json) {
                            let response = JSON.parse(response_json);
                            
                            if (response.error == 1) {
                                
                            }
                            else if (response.data && response.data.message == "Phone already registered") {
                                let urlToBeOpen = window.location.origin;
                                window.location.replace(urlToBeOpen);
                            }
                            else if (response.data && response.data.result == "Not Match") {
                                let user_action = "User got error on innerpages/enterphone file handleNext function";
                                let body = url.dynamic_content.error_message.incorrect_code.en
                                functions.logUserError(user_action, body);
                                
                            }
                            else if (response.data && response.data.result == "Match") {
                                // let success_action = "User's phone number is verified successfully enterphone page"
                                // let image = 'phone_number_verified_enterphone_page.png'
                                // functions.logUserActivity(success_action, image);

                                dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                                localStorage.clear();
                                localStorage.setItem("user_signup_process", "false")
                                localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                                
                                const { login_token, sid, subid, program_id } = response.data
                                let username1 = response.data.username

                                if (sid && sid != "") {
                                    localStorage.setItem("session_entered_id", sid);
                                }

                                if (login_token && login_token != "") {
                                    functions.ValidateToken(subid, login_token, function (validate_response) {
                                        
                                        let res_json = JSON.parse(validate_response)
                                        if (res_json.data.is_valid == 1) {
                                            
                                            localStorage.setItem("auto_id", subid)
                                            cookie.save("auto_id", subid, { path: "/" })
                                            cookie.save("username", username1, { path: "/" });
                                            if (session_id == null) {
                                                session.createSession(subid, 'login', function (response) {
                                                    session.checkSession(subid, "login", 'false');
                                                    let element = document.getElementById("login_complete")
                                                    
                                                    element.click();
                                                    if (cookie.load("accept_auto_id") && cookie.load("relative_id") && cookie.load("accept_auto_id") == cookie.load("auto_id")) {
                                                        functions.addRelative(self, subid, cookie.load("relative_id"), username1);
                                                    }
                                                    else {
                                                        functions.getUserData(self, username1, 'login')
                                                    }
                                                })
                                            }
                                            else {
                                                session.checkSession(subid, "login", 'false');
                                                let element = document.getElementById("login_complete")
                                                
                                                element.click();
                                                if (cookie.load("accept_auto_id") && cookie.load("relative_id") && cookie.load("accept_auto_id") == cookie.load("auto_id")) {
                                                    functions.addRelative(self, subid, cookie.load("relative_id"), username1);
                                                }
                                                else {
                                                    functions.getUserData(self, username1, 'login')
                                                }
                                            }
                                        } else {
                                            cookie.save("username", username1, { path: "/" });
                                            localStorage.setItem("login_error", "true")
                                            functions.pushToRoute(self, "login", "main_index")
                                        }
                                    })
                                } else if (session_id == null) {
                                    cookie.save("auto_id", subid, { path: "/" })
                                    localStorage.setItem("auto_id", subid)
                                    cookie.save("username", username1, { path: "/" });
                                    session.createSession(subid, 'login', function (response) {
                                        session.checkSession(subid, "login", 'false');
                                        let element = document.getElementById("login_complete")
                                        
                                        element.click();
                                        if (cookie.load("accept_auto_id") && cookie.load("relative_id") && cookie.load("accept_auto_id") == cookie.load("auto_id")) {
                                            functions.addRelative(self, subid, cookie.load("relative_id"), username1);
                                        }
                                        else {
                                            functions.getUserData(self, username1, 'login')
                                        }
                                    })
                                } else {
                                    cookie.save("auto_id", subid, { path: "/" })
                                    localStorage.setItem("auto_id", subid)
                                    cookie.save("username", username1, { path: "/" });
                                    session.checkSession(subid, "login", 'false');
                                    let element = document.getElementById("login_complete")
                                    
                                    element.click();
                                    if (cookie.load("accept_auto_id") && cookie.load("relative_id") && cookie.load("accept_auto_id") == cookie.load("auto_id")) {
                                        functions.addRelative(self, subid, cookie.load("relative_id"), username1);
                                    }
                                    else {
                                        functions.getUserData(self, username1, 'login')
                                    }
                                }

                                if (program_id.length > 4) {
                                    localStorage.setItem("perks_or_main", "perks")
                                    localStorage.setItem("session_entered_id", program_id.toString())
                                } else {
                                    localStorage.setItem("program_visible", program_id.toString())
                                    localStorage.setItem("session_entered_id", program_id.toString())
                                }
                            }
                        });
                    }

                    break;
                case "#campaign_link":
                    functions.invokeUserEmailVerify(auto_id)
                    dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                    localStorage.clear();
                    localStorage.setItem("user_signup_process", "false")
                    localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                    let token1_link = []
                    let temp_token1_link = path[1].trim();
                    token1_link = temp_token1_link.split("&")

                    let client_id1_link = [];
                    let temp_client_id1_link = token1_link[0].trim()
                    client_id1_link = temp_client_id1_link.split("=")

                    let username1_link = [];
                    let temp_username1_link = token1_link[1].trim()
                    username1_link = temp_username1_link.split("=")

                    let sub_id1_link = [];
                    let temp_sub_id1_link = token1_link[3].trim()
                    sub_id1_link = temp_sub_id1_link.split("=")

                    let sid1_link = [];
                    let temp_sid1_link = token1_link[4].trim()
                    sid1_link = temp_sid1_link.split("=")
                    cookie.save("username", username1_link[1], { path: "/" });
                    cookie.save("auto_id", sub_id1_link[1], { path: "/" })
                    localStorage.setItem("auto_id", sub_id1_link[1])
                    if (session_id == null) {
                        session.createSession(sub_id1_link[1], '', function (response) {
                            functions.getUserData(self, username1_link[1], 'login')
                            localStorage.setItem("email_verified", true);
                        })
                    }
                    else {
                        session.checkSession(sub_id1_link[1], "login", 'false');
                        functions.getUserData(self, username1_link[1], 'login')
                    }
                    break;
                case "#signup_link":
                    functions.invokeUserEmailVerify(auto_id)
                    dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                    localStorage.clear();
                    localStorage.setItem("user_signup_process", "false")
                    localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                    let temp_token2 = path[1].trim();
                    let token2 = temp_token2.split("&")

                    let client_id2 = [];
                    let temp_client_id2 = token2[0].trim()
                    client_id2 = temp_client_id2.split("=")
                    self.setState({ main_loader: true })
                    let username = [];
                    let temp_username = token2[1].trim()
                    username = temp_username.split("=")

                    let sub_id = [];
                    let temp_sub_id = token2[3].trim()
                    sub_id = temp_sub_id.split("=")
                    let winner_id = cookie.load("winner_id") ? cookie.load("winner_id") : ""
                    if (winner_id != "" && typeof winner_id != undefined && winner_id != sub_id[1]) {
                        cookie.remove("winner_id", { path: "/" })
                        cookie.remove("winner_program_id", { path: "/" })
                    }
                    cookie.save("username", username[1], { path: "/" });
                    cookie.save("auto_id", sub_id[1], { path: "/" })
                    localStorage.setItem("auto_id", sub_id[1])
                    let userdata = [];

                    // let dynamo_userdata = { 'email_verified': true, 'send_pending_mails': 1 };
                    if (session_id == null) {
                        session.createSession(sub_id[1], '', function (response) {
                            let signup_json = {
                                username: username[1],
                                "auto_id": sub_id[1]
                            }
                            functions.adminSignupConfirm(this, signup_json, function (response_json) {
                                let response = JSON.parse(response_json);
                                if (response.status == 200 && response.data.success == 1) {
                                    functions.getUserData(self, username[1], 'signup')
                                    localStorage.setItem("email_verified", true);
                                }
                                else if (response.data && response?.data?.code == "NotAuthorizedException") {
                                    functions.getUserData(self, username[1])
                                    localStorage.setItem("email_verified", true);
                                } else {
                                    self.setState({ loader: false })
                                    self.setState({ main_loader: false })
                                    window.location.hash = ""
                                }
                            })
                        })
                    }
                    else {
                        let signup_json = {
                            username: username[1],
                            "auto_id": sub_id[1]
                        }
                        session.checkSession(sub_id1_link[1], "login", 'false');
                        functions.adminSignupConfirm(this, signup_json, function (response_json) {
                            let response = JSON.parse(response_json);
                            if (response.status == 200 && response.data.success == 1) {
                                functions.getUserData(self, username[1], 'signup')
                                localStorage.setItem("email_verified", true);
                            } else if (response.data && response?.data?.code == "NotAuthorizedException") {
                                functions.getUserData(self, username[1])
                                localStorage.setItem("email_verified", true);
                            } else {
                                self.setState({ loader: false })
                                self.setState({ main_loader: false })
                                window.location.hash = ""
                            }
                        })
                    }
                    break;
                case "#connect":
                    // this functon is used to Verify user email if user coming through email.
                    functions.invokeUserEmailVerify(auto_id)
                    let token3 = []
                    let temp_token3 = path[1].trim();
                    token3 = temp_token3.split("&")

                    let sub_id3 = [];
                    let temp_sub_id3 = token3[0].trim()
                    sub_id3 = temp_sub_id3.split("=")
                    let relative_id3 = [];
                    let temp_relative_id3 = token3[1].trim()
                    let is_parent_link = token3[2] ? token3[2].trim() : false
                    
                    relative_id3 = temp_relative_id3.split("=")
                    let email_alternative_popup = is_parent_link ? is_parent_link.split("=") : false
                    // 

                    
                    
                    

                    localStorage.setItem('parent_invite_id', sub_id3[1]);

                    if (email_alternative_popup[1] == "true") {
                        cookie.save("parent_invite_id", sub_id3[1], { path: "/" });

                        

                        cookie.save("showAlternativeEmailPopup", true, { path: "/" })
                    }
                    else {
                        cookie.remove("showAlternativeEmailPopup", { path: "/" })

                    }
                   
                    if (sub_id3[1] == cookie.load("auto_id")) {
                       
                        functions.addRelative(self, sub_id3[1], relative_id3[1], cookie.load("username"))
                        functions.getLandingPage(self);
                    } else {
                        
                        cookie.remove("auto_id", { path: '/' })
                        cookie.remove("username", { path: '/' })
                        dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                        localStorage.clear();
                        localStorage.setItem("user_signup_process", "false")
                        localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                        cookie.save("accept_auto_id", sub_id3[1], { path: "/" })
                        cookie.save("relative_id", relative_id3[1], { path: "/" })
                        let check_existing = sub_id3[1].substring(0, 5)
                        



                        let session_id_connect = cookie.load("session_id") ? cookie.load("session_id") : null
                        if (session_id_connect == null) {
                            session.createSession("null", '', function (response) {
                                if (check_existing == "temp_") {
                                    // if (email_alternative_popup[1] == "true") {
                                    //     functions.pushToRoute(self, "login", "main_index")
                                    // } else
                                    functions.pushToRoute(self, "signup", "main_index")
                                } else {
                                    functions.getLandingPage(self);
                                }
                            })
                        }
                        else {
                            if (check_existing == "temp_") {
                                // if (email_alternative_popup[1] == "true") {
                                //     functions.pushToRoute(self, "login", "main_index")
                                // } else
                                functions.pushToRoute(self, "signup", "main_index")
                            } else {
                                functions.getLandingPage(self);
                            }
                        }
                    }
                    break;
                case "#winner":
                    let token4 = []
                    let temp_token4 = path[1].trim();
                    token4 = temp_token4.split("&")
                    let sub_id4 = [];
                    let temp_sub_id4 = token4[0].trim()
                    sub_id4 = temp_sub_id4.split("=")
                    let program_id4 = [];
                    let temp_program_id4 = token4[1].trim()
                    program_id4 = temp_program_id4.split("=")
                    let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
                    auto_id = auto_id ? auto_id : ""
                    self.setState({ main_loader: false })
                    if (auto_id != "" && typeof auto_id != undefined) {
                        if (auto_id == sub_id4[1]) {
                            cookie.save("winner_id", sub_id4[1], { path: "/" });
                            cookie.save("winner_program_id", program_id4[1], { path: "/" });
                            cookie.save("winner_later", 'false', { path: '/' });
                            // functions.pushToRoute(self, "")
                            functions.getLandingPage(self);
                        }
                    }
                    else {
                        cookie.save("winner_later", 'false', { path: '/' })
                        cookie.save("winner_id", sub_id4[1], { path: "/" });
                        cookie.save("winner_program_id", program_id4[1], { path: "/" });
                        // functions.getLandingPage(self);
                        functions.pushToRoute(self, "home")
                    }
                    self.OnPageLoad()
                    break;
                case "#cp":
                    // this functon is used to Verify user email if user coming through email.
                    functions.invokeUserEmailVerify(auto_id)
                    dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                    localStorage.clear();
                    localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                    let token_cp = [];
                    let temp_token_cp = path[1].trim();
                    token_cp = temp_token_cp.split("&")
                    let username_cp = [];
                    let temp_username_cp = token_cp[0].trim()
                    username_cp = temp_username_cp.split("=")
                    let sub_id_cp = [];
                    let temp_sub_id_cp = token_cp[1].trim()
                    sub_id_cp = temp_sub_id_cp.split("=")
                    cookie.save("username", username_cp[1], { path: "/" });
                    cookie.save("auto_id", sub_id_cp[1], { path: "/" })
                    localStorage.setItem("auto_id", sub_id_cp[1])
                    let userdata_cp = [];
                    let dynamo_userdata_cp = { 'email_verified': "true", double_optin: "true" }
                    // localStorage.setItem("email_verified", true);
                    functions.updateUserData(this, userdata_cp, dynamo_userdata_cp);
                    functions.pushToRoute(this, "create_password")
                    break;
                case "#search_user":
                    dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                    localStorage.clear();
                    localStorage.setItem("user_signup_process", "false")
                    cookie.remove("auto_id", { path: '/' })
                    cookie.remove("username", { path: '/' })
                    localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                    let temp_token_search = path[1].trim();
                    let token_search = temp_token_search.split("&")
                    
                    if (token_search[1]) {
                        let program_id_search = token_search[1].trim().split("=")[1]
                        
                        if (program_id_search.length > 4) {
                            localStorage.setItem("perks_or_main", "perks")
                            localStorage.setItem("session_entered_id", program_id_search.toString())
                        } else {
                            localStorage.setItem("session_entered_id", program_id_search.toString())
                        }
                    }
                    if (token_search[0]) {
                        let username_search = token_search[0].trim().split("=")[1]
                        let decoded_username = username_search && base64.decode(username_search)
                        
                        localStorage.setItem("email_verified", 'true')
                        if (session_id == null) {
                            session.createSession("null", '', function (response) {
                                functions.getUserData(self, decoded_username, 'login')
                            })
                        }
                        else {
                            session.checkSession("null", "login", 'false');
                            functions.getUserData(self, decoded_username, 'login')
                        }
                    }
                    self.setState({ main_loader: true })

                    break;
                case "#login_cam":
                    functions.invokeUserEmailVerify(auto_id)
                    
                    if (parameters.action == "s_u" && parameters.email != "") {
                        
                        dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                        localStorage.clear();
                        localStorage.setItem("user_signup_process", "false")
                        cookie.remove("auto_id", { path: '/' })
                        cookie.remove("username", { path: '/' })
                        cookie.remove("child_popup", { path: '/' })
                        localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                        let username_search = parameters.email
                        let decoded_username = username_search && base64.decode(username_search)
                        if (parameters.p_id) {
                            let program_id_search = parameters.p_id
                            
                            if (program_id_search.length > 4) {
                                localStorage.setItem("perks_or_main", "perks")
                                localStorage.setItem("session_entered_id", program_id_search.toString())
                            } else {
                                localStorage.setItem("session_entered_id", program_id_search.toString())
                            }
                        }
                        if (parameters.cpop === "true") {
                            cookie.save("child_popup", parameters.cpop, { path: '/' })
                        }
                        let log_params = {
                            sub_id: parameters.sub_id ? parameters.sub_id : "",
                            email: decoded_username,
                            program_id: parameters.p_id ? parameters.p_id : "",
                            email_category: parameters.ec ? parameters.ec : "",
                            click_link: parameters.cl ? parameters.cl : ""
                        }
                        
                        functions.callLambdaFunctionUser(self, log_params)
                        
                        localStorage.setItem("email_verified", 'true')
                        let userdata = []
                        let visible_program_to_user = []
                        visible_program_to_user.push(parameters.p_id)
                        let dynamo_userdata = {
                            visible_program_to_user: visible_program_to_user
                        }
                        
                        
                        if (session_id == null) {
                            
                            if (parameters.sp == "t") {
                                cookie.save("auto_id", parameters.sub_id, { path: '/' })
                                cookie.save("username", decoded_username, { path: '/' })
                                functions.updateUserDataPass(self, userdata, dynamo_userdata, function (err, passData) {
                                    if (err) {
                                    }
                                    else {
                                        
                                        session.createSession(parameters.sub_id, '', function (response) {
                                            functions.getUserData(self, decoded_username, 'login')
                                            localStorage.setItem("email_verified", true);
                                        })
                                    }
                                })
                            } else {
                                
                                session.createSession(parameters.sub_id, '', function (response) {
                                    localStorage.setItem("email_verified", true);
                                    if (parameters.lp == "addchild") {
                                        functions.getUserData(self, decoded_username, 'login', parameters.lp)
                                    } else {
                                        functions.getUserData(self, decoded_username, 'login')
                                    }

                                })
                            }
                        } else {
                            
                            if (parameters.sp == "t") {
                                cookie.save("auto_id", parameters.sub_id, { path: '/' })
                                cookie.save("username", decoded_username, { path: '/' })
                                functions.updateUserDataPass(self, userdata, dynamo_userdata, function (err, passData) {
                                    if (err) {
                                        
                                    }
                                    else {
                                        
                                        session.checkSession(parameters.sub_id, "login", 'false');
                                        if (parameters.lp == "addchild") {
                                            functions.getUserData(self, decoded_username, 'login', parameters.lp)
                                        } else {
                                            functions.getUserData(self, decoded_username, 'login')
                                        }

                                    }
                                })
                            } else {
                                
                                session.checkSession(parameters.sub_id, "login", 'false');
                                if (parameters.lp == "addchild") {
                                    functions.getUserData(self, decoded_username, 'login', parameters.lp)
                                } else {
                                    functions.getUserData(self, decoded_username, 'login')
                                }

                            }

                        }
                        self.setState({ main_loader: true })
                    } else if (parameters.action == "s_u_n" && parameters.email != "") {
                        
                        dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                        localStorage.clear();
                        localStorage.setItem("user_signup_process", "false")
                        cookie.remove("auto_id", { path: '/' })
                        cookie.remove("username", { path: '/' })
                        localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                        let username_search = parameters.email
                        if (parameters.p_id) {
                            let program_id_search = parameters.p_id
                            
                            if (program_id_search.length > 4) {
                                localStorage.setItem("perks_or_main", "perks")
                                localStorage.setItem("session_entered_id", program_id_search.toString())
                            } else {
                                localStorage.setItem("session_entered_id", program_id_search.toString())
                            }
                        }
                        if (parameters.p_id) {
                            let program_id_search = parameters.p_id
                            
                            if (program_id_search.length > 4) {
                                localStorage.setItem("perks_or_main", "perks")
                                localStorage.setItem("session_entered_id", program_id_search.toString())
                            } else {
                                localStorage.setItem("session_entered_id", program_id_search.toString())
                            }
                        }
                        
                        localStorage.setItem("email_verified", 'true')
                        functions.getUserData(self, username_search, 'login')
                        self.setState({ main_loader: true })
                    } else if (parameters.action == "s_o_n" && parameters.email != "") {
                        
                        dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                        localStorage.clear();
                        localStorage.setItem("user_signup_process", "false")
                        cookie.remove("auto_id", { path: '/' })
                        cookie.remove("username", { path: '/' })
                        localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                        let username_search = parameters.email
                        let decoded_username = username_search && base64.decode(username_search)
                        if (parameters.p_id) {
                            let program_id_search = parameters.p_id.toString()
                            let button_type = parameters.button_type
                            
                            
                            if (program_id_search.length > 4) {
                                localStorage.setItem("perks_or_main", "perks")
                                localStorage.setItem("session_entered_id", program_id_search)
                            } else {
                                localStorage.setItem("session_entered_id", program_id_search)
                            }
                            let json_data = {
                                program_id: program_id_search,
                                sub_id: parameters.sub_id
                            }
                            
                            functions.getProgramDetailSON(self, json_data, function (json_response) {
                                let response = JSON.parse(json_response)

                                if (response.data && response.data[0] && response.data[0].is_eligible == 1) {
                                    let is_sub_id = "yes"
                                    let po_sub_id = response.data[0].sub_id
                                    let partner_id = response.data[0].partner_id
                                    let term_id = response.data[0].term_id
                                    let program_type = response.data[0].program_type
                                    let registration_source = response.data[0].registration_source
                                    let point_type = 'entry';
                                    let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
                                    let is_recommend = "false";
                                    let is_direct = 1;
                                    let userdata = [];
                                    cookie.save("auto_id", po_sub_id, { path: '/' })
                                    cookie.save("username", decoded_username, { path: '/' })
                                    if (json_data.program_id == "9361" || json_data.program_id == "1262") {
                                        let dynamo_userdata = { 'nestle_redeem_type': button_type };
                                        localStorage.setItem("nestle_redeem_type", button_type);
                                        
                                        functions.updateUserDataPass(self, userdata, dynamo_userdata, function (err, passData) {
                                            
                                            
                                            if (err) {
                                            }
                                            else {
                                                let user_eligible = ""
                                                functions.contestEntry(self, is_sub_id, po_sub_id, tracking_id, program_id_search, partner_id, registration_source, point_type, is_direct, user_eligible, is_recommend, program_type, term_id, "", "", "", function (response_json) {
                                                    let response = JSON.parse(response_json);
                                                    if (response.status == 200) {
                                                        functions.getUserData(self, decoded_username, 'login')
                                                        self.setState({ main_loader: true })
                                                    } else {
                                                        
                                                        localStorage.setItem("email_verified", 'true')
                                                        functions.getUserData(self, decoded_username, 'login')
                                                        self.setState({ main_loader: true })
                                                    }
                                                })
                                            }
                                        })
                                    } else {
                                        let user_eligible = ""
                                        localStorage.setItem("programSONSuccess", 'true')
                                        
                                        if (!response.data[0].phone_number || response.data[0].phone_number == "") {
                                            let pending_fields = response.data[0].pending_fields ? response.data[0].pending_fields : [];
                                            pending_fields.push("phone_number");
                                            response.data[0].pending_fields = JSON.stringify(pending_fields);
                                        }
                                        delete response.data[0]?.phone_number;
                                        response.data[0].has_enter = 1;
                                        
                                        localStorage.setItem("program_detail", JSON.stringify(response.data[0]))
                                        self.props.handleProgramDetail(response.data[0])
                                        localStorage.setItem("program_list", JSON.stringify([response.data[0]]))
                                        functions.getUserData(self, decoded_username, 'login')
                                        self.setState({ main_loader: true })
                                        functions.contestEntry(self, is_sub_id, po_sub_id, tracking_id, program_id_search, partner_id, registration_source, point_type, is_direct, user_eligible, is_recommend, program_type, term_id, "", "", "", function (response_json) {
                                            let response = JSON.parse(response_json);
                                            
                                            if (response.status == 200) {
                                                // functions.getUserData(self, decoded_username, 'login')
                                                // self.setState({ main_loader: true })
                                            } else {
                                                localStorage.setItem("email_verified", 'true')
                                                // 

                                            }
                                        })
                                    }
                                } else {
                                    
                                    localStorage.setItem("email_verified", 'true')
                                    if (session_id == null) {
                                        session.createSession(parameters.sub_id, '', function (response) {
                                            functions.getUserData(self, decoded_username, 'login')
                                        })
                                    }
                                    else {
                                        session.checkSession(parameters.sub_id, "login", 'false');
                                        functions.getUserData(self, decoded_username, 'login')
                                    }
                                    self.setState({ main_loader: true })
                                }
                            })
                        } else {
                            
                            localStorage.setItem("email_verified", 'true')
                            if (session_id == null) {
                                session.createSession(parameters.sub_id, '', function (response) {
                                    functions.getUserData(self, decoded_username, 'login')
                                })
                            }
                            else {
                                session.checkSession(parameters.sub_id, "login", 'false');
                                functions.getUserData(self, decoded_username, 'login')
                            }
                            self.setState({ main_loader: true })
                        }
                    } else if (parameters.action == "s_o" && parameters.email != "") {
                        
                        dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                        localStorage.clear();
                        localStorage.setItem("user_signup_process", "false")
                        cookie.remove("auto_id", { path: '/' })
                        cookie.remove("username", { path: '/' })
                        localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                        let username_search = parameters?.email && base64.decode(parameters.email)
                        if (parameters.p_id) {
                            let program_id_search = parameters.p_id.toString()
                            let button_type = parameters.button_type
                            
                            
                            if (program_id_search.length > 4) {
                                localStorage.setItem("perks_or_main", "perks")
                                localStorage.setItem("session_entered_id", program_id_search)
                            } else {
                                localStorage.setItem("session_entered_id", program_id_search)
                            }
                            let json_data = {
                                program_id: program_id_search,
                                email: username_search
                            }
                            if (session_id == null) {
                                session.createSession(parameters.sub_id, '', function (response) {
                                    localStorage.setItem("email_verified", true);
                                })
                            }
                            else {
                                session.checkSession(parameters.sub_id, "login", 'false');
                            }
                            functions.getProgramDetail(self, json_data, function (json_response) {
                                let response = JSON.parse(json_response)
                                
                                if (response.data.success == 1 && response.data.is_eligible == 1) {
                                    let is_sub_id = "yes"
                                    let po_sub_id = response.data.sub_id
                                    let partner_id = response.data.data.partner_id
                                    let program_type = response.data.data.program_type
                                    let term_id = response.data.data.term_id ? response.data.data.term_id : "null"
                                    let registration_source = response.data.data.registration_source
                                    let point_type = 'entry';
                                    let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
                                    let is_recommend = "false";
                                    let is_direct = 1;
                                    let userdata = [];
                                    let dynamo_userdata = { 'nestle_redeem_type': button_type };
                                    localStorage.setItem("nestle_redeem_type", button_type);
                                    
                                    cookie.save("auto_id", po_sub_id, { path: '/' })
                                    cookie.save("username", username_search, { path: '/' })
                                    functions.updateUserDataPass(self, userdata, dynamo_userdata, function (err, passData) {
                                        
                                        
                                        if (err) {
                                        }
                                        else {
                                            let user_eligible = ""
                                            functions.contestEntry(self, is_sub_id, po_sub_id, tracking_id, program_id_search, partner_id, registration_source, point_type, is_direct, user_eligible, is_recommend, program_type, term_id, "", "", "", function (response_json) {
                                                let response = JSON.parse(response_json);
                                                if (response.status == 200) {
                                                    functions.getUserData(self, username_search, 'login')
                                                    self.setState({ main_loader: true })
                                                } else {
                                                    
                                                    localStorage.setItem("email_verified", 'true')
                                                    functions.getUserData(self, username_search, 'login')
                                                    self.setState({ main_loader: true })
                                                }
                                            })
                                        }
                                    })

                                } else {
                                    
                                    localStorage.setItem("email_verified", 'true')
                                    functions.getUserData(self, username_search, 'login')
                                    self.setState({ main_loader: true })
                                }
                            })
                        } else {
                            
                            localStorage.setItem("email_verified", 'true')
                            if (session_id == null) {
                                session.createSession(parameters.sub_id, '', function (response) {
                                    functions.getUserData(self, username_search, 'login')
                                })
                            }
                            else {
                                session.checkSession(parameters.sub_id, "login", 'false');
                                functions.getUserData(self, username_search, 'login')
                            }
                            self.setState({ main_loader: true })
                        }
                    }
                    break;
                case "#rd":
                    functions.invokeUserEmailVerify(auto_id)
                    
                    
                    dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                    localStorage.clear();
                    cookie.remove("auto_id", { path: '/' })
                    cookie.remove("username", { path: '/' })
                    localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                    let username_search = parameters.email
                    let decoded_username = username_search && base64.decode(username_search)
                    let log_params = {
                        sub_id: parameters.sub_id ? parameters.sub_id : "",
                        email: decoded_username,
                        program_id: parameters.p_id ? parameters.p_id : "",
                        email_category: parameters.ec ? parameters.ec : "",
                        click_link: parameters.cl ? parameters.cl : ""
                    }
                    
                    functions.callLambdaFunctionUser(self, log_params)
                    setTimeout(() => {
                        if (parameters.p_id == "3508") {
                            
                            window.location.replace("https://get.guardtree.ca/familyone-2/")
                        } else if (parameters.p_id == "5475") {
                            
                            window.location.replace("https://get.guardtree.ca/familyone/")
                        }
                    }, 1000);
                    break;
                case "#act_link":
                    dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                    localStorage.clear();
                    localStorage.setItem("user_signup_process", "false")
                    cookie.remove("auto_id", { path: '/' })
                    cookie.remove("username", { path: '/' })
                    localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                    let temp_token_act = path[1].trim();
                    let token_act = temp_token_act.split("&")
                    
                    if (token_act[1]) {
                        let sub_id_act = token_act[1].trim().split("=")[1]
                        
                        cookie.save("auto_id", sub_id_act, { path: '/' })
                        let final_json_act = {
                            sub_id: sub_id_act,
                            welcome_email: "true"
                        }
                        functions.SendActivationEmail(self, final_json_act)
                    }
                    if (token_act[0]) {
                        let username_act = token_act[0].trim().split("=")[1]
                        let sub_id_act = token_act[1] ? token_act[1].trim().split("=")[1] : "null"
                        
                        localStorage.setItem("email_verified", 'true')
                        if (session_id == null) {
                            session.createSession(sub_id_act, '', function (response) {
                                functions.getUserData(self, username_act, 'login')
                            })
                        }
                        else {
                            session.checkSession(sub_id_act, "login", 'false');
                            functions.getUserData(self, username_act, 'login')
                        }
                    }
                    self.setState({ main_loader: true })

                    break;
                case "#campaignlink":
                    functions.invokeUserEmailVerify(auto_id)
                    
                    const { p_id, redirect_url } = parameters
                    let camp_user_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
                    camp_user_id = camp_user_id ? camp_user_id : ""
                    if (path[1]) {

                        let camp_id = p_id ? p_id : ""
                        
                        if (camp_id != "") {
                            let date = new Date()
                            date = date.setHours(date.getHours() + 4)
                            
                            cookie.save('camp_id', camp_id, { path: '/', expires: new Date(date) });
                            // cookie.save("camp_id", camp_id, { path: '/', expires: new Date(date) })
                            let finaljson = {
                                "action": "get_campaign",
                                "campaign_id": camp_id
                            }
                            functions.getCampaignInfo(self, finaljson, function (json_response) {
                                let camp_res = JSON.parse(json_response)
                                
                                if (camp_res.data.success == 1) {
                                    
                                    cookie.save("camp_program_id", camp_res.data.data.program_id, { path: '/' })
                                    if (redirect_url && redirect_url != "" && typeof redirect_url != undefined && redirect_url != null) {

                                    } else {
                                        if (camp_res.data.data.program_type == "perk") {
                                            localStorage.setItem("perks_or_main", "perks")
                                            localStorage.setItem("session_entered_id", camp_res.data.data.program_id.toString())
                                        } else {
                                            localStorage.setItem("session_entered_id", camp_res.data.data.program_id.toString())
                                        }
                                    }
                                    let session_id_camp = cookie.load("session_id") ? cookie.load("session_id") : null
                                    if (session_id_camp == null) {
                                        session.createSession("null", '', function (response) {
                                            if (camp_user_id == null) {
                                                functions.pushToRoute(self, "")
                                            }
                                            else {
                                                localStorage.setItem("fb_post", "true")
                                                self.getDynamicContent()
                                            }
                                        })
                                    }
                                    else {
                                        if (camp_user_id == null) {
                                            functions.pushToRoute(self, "")
                                        }
                                        else {
                                            localStorage.setItem("fb_post", "true")
                                            self.getDynamicContent()
                                        }
                                    }
                                } else {
                                    functions.pushToRoute(self, "")
                                }
                            })
                        }
                        else {
                            functions.getLandingPage(this);
                        }
                    } else {
                        functions.getLandingPage(this);
                    }
                    break;
                case "#relativebounced":
                    dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                    localStorage.clear();
                    localStorage.setItem("user_signup_process", "false")
                    localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                    cookie.remove("auto_id", { path: "/" })
                    cookie.remove("social_medium", { path: "/" })
                    cookie.remove("social_shared_prog_id", { path: "/" })
                    cookie.remove("social_shared_refered_id", { path: "/" })
                    cookie.remove("winner_later", { path: "/" })
                    cookie.remove("provider", { path: "/" })
                    cookie.remove("facebook_id", { path: "/" })
                    cookie.remove("google_id", { path: "/" })
                    cookie.remove("tracking_id", { path: "/" })
                    cookie.remove("username", { path: "/" })
                    cookie.remove("accept_auto_id", { path: "/" })
                    cookie.remove("relative_id", { path: "/" })
                    cookie.remove("winner_id", { path: "/" })
                    cookie.remove("winner_program_id", { path: "/" })
                    cookie.remove("winner_claim_days", { path: "/" })
                    cookie.remove("claim_status", { path: "/" })
                    cookie.remove("email_changed", { path: "/" })
                    cookie.remove("alternative_email", { path: "/" })
                    
                    const { token } = parameters
                    let decrypt_object = JSON.parse(cryptr.decrypt(token))
                    
                    let finaljson_rb = {
                        email: decrypt_object.user_email,
                        sub_id: decrypt_object.sub_id
                    }
                    functions.getUserProfile(this, finaljson_rb, function (response_json) {
                        let response = JSON.parse(response_json);
                        
                        if (response.catch_error) {
                            let user_action = "User got error on main index relativebounced function";
                            let body = response.message
                            functions.logUserError(user_action, body);
                            self.setState({ loader: false, show_error_message: response.message })
                        }
                        else if (!response.catch_error) {

                            if (response.data.error === 1) {
                                let user_action = "User got error on main index relativebounced function";
                                let body = response.data.message
                                functions.logUserError(user_action, body);
                                self.setState({ loader: false, show_error_message: response.data.message })
                            } else if (response.data.statusCode === 400) {
                                let user_action = "User got error on main index relativebounced function";
                                let body = response.data.message
                                functions.logUserError(user_action, body);
                                self.setState({ loader: false, show_error_message: response.data.message })
                            }

                            else if (response.status === 200) {
                                if (response.data && response.data.dynamo && response.data.dynamo.Items && response.data.dynamo.Items.length == 0) {
                                    self.setState({ loader: false })
                                    functions.pushToRoute(self, "", "main_index")
                                } else {
                                    let relation_found = false
                                    let data = response.data;
                                    let cognito_data = data.cognito ? data.cognito.UserAttributes : []
                                    let dynamo_data = data.dynamo.Items ? data.dynamo.Items[0] : {}
                                    let relation_data = data.relation_data ? data.relation_data : {}
                                    let program_data = data.program_data ? data.program_data : []
                                    functions.storeCookie(cognito_data, dynamo_data, relation_data, program_data);
                                    if (Object.keys(relation_data).length > 0) {
                                        for (var key in relation_data) {
                                            
                                            relation_data[key].forEach((element, index) => {
                                                
                                                if (element.id == decrypt_object.relative_id) {
                                                    
                                                    relation_found = true
                                                    localStorage.setItem("bounce_rel_id", element.id)
                                                    functions.getDynamicContent(function (response_json) {
                                                        functions.pushToRoute(self, "buildaddrelative", 'main_index');
                                                    })
                                                }
                                            });
                                        }
                                    }
                                    if (relation_found == false) {
                                        functions.getLandingPage(self)
                                    }
                                }
                            }
                        }
                    })
                    break;
                case "#decline":
                    dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                    localStorage.clear();
                    localStorage.setItem("user_signup_process", "false")
                    cookie.remove("auto_id", { path: '/' })
                    cookie.remove("username", { path: '/' })
                    localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                    
                    
                    let decline_sub_id = parameters.user_id ? parameters.user_id : ""
                    let decline_relative_id = parameters.relative_id ? parameters.relative_id : ""
                    if (decline_sub_id != "" && decline_relative_id != "") {
                        if (session_id == null) {
                            session.createSession(decline_sub_id, '', function (response) {
                            })
                        }
                        else {
                            session.checkSession(decline_sub_id, "login", 'false');
                        }
                        functions.DeleteRelationInMail(this, decline_sub_id, decline_relative_id)
                        functions.pushToRoute(self, "")
                        this.getDynamicContent()
                    } else {
                        functions.pushToRoute(self, "")
                        this.getDynamicContent()
                    }
                    break;
                case "#ftm":
                    let ftm_child_time = path[1].trim();
                    
                    let query;
                    query = new URLSearchParams(ftm_child_time)
                    let ftm_child_time_stamp = query.get('child_ts')
                    let l_page = query.get('l_p')
                    
                    let session_id_connect = cookie.load("session_id") ? cookie.load("session_id") : null;
                    let auto_id_get = cookie.load('auto_id') ? cookie.load("auto_id") : localStorage.getItem("auto_id");
                    if (ftm_child_time_stamp) cookie.save("ftm_child_time_stamp", ftm_child_time_stamp, { path: "/" })
                    
                    if (session_id_connect == null) {
                        session.createSession("null", '', function (response) {
                            functions.pushToRoute(self, l_page);
                        })
                        localStorage.setItem("ftm_home", "true")
                    }
                    else {
                        if (auto_id_get && ftm_child_time_stamp) {
                            let all_local_storage = this.allLocalStorage();
                            let api_auth = localStorage.getItem("api_auth")
                            all_local_storage.forEach((element, index) => {
                                for (var key in element) {
                                    if (key != "api_auth" && key != "dynamic_content") {
                                        localStorage.removeItem(key)
                                    }
                                    else {
                                    }
                                }
                            });
                            let all_cookie = cookie.loadAll();
                            let all_cookie_length = Object.keys(all_cookie).length;
                            let index = 0;
                            cookie.remove("api_auth", { path: "/" })
                            cookie.remove("api_auth", { path: "/" })
                            for (var key in all_cookie) {
                                if (key != 'session_id') {
                                    cookie.remove(key, { path: "/" })
                                }
                                index = index + 1;
                                let local_storage = this.allLocalStorage();
                                if (local_storage && local_storage.length == 3) {
                                    if (index == all_cookie_length) {
                                        localStorage.setItem("logged_in", 'false')
                                        localStorage.setItem("api_auth", api_auth)
                                        localStorage.setItem('logout-event', 'logout' + Math.random());
                                        this.props.history.push("/")
                                        let session_auto_id = cookie.load("session_auto_id") ? cookie.load("session_auto_id") : null
                                        session.checkSession(session_auto_id, '', 'true');
                                    }
                                }
                                else {
                                    // this.handleLogout()
                                }
                            }
                            if (ftm_child_time_stamp) cookie.save("ftm_child_time_stamp", ftm_child_time_stamp, { path: "/" })
                            localStorage.setItem("ftm_home", "true")
                            functions.getLandingPage(self);
                        }
                        else {
                            localStorage.setItem("ftm_home", "true")
                            functions.pushToRoute(self, l_page);
                        }
                    }
                    break;
                case "#phoneTransferRequestAccepted":
                    functions.invokeUserEmailVerify(auto_id)
                    const params = new URLSearchParams(path[1]);
                    const phone = params.get('phone_number');
                    const email = params.get('username');
                    const subId = params.get('sub_id');

                    if (phone && email) {

                        let finaljson = {
                            email: email
                        };

                        functions.CheckUserExistenceInOldNewSytsem(this, finaljson, function (response1_json) {
                            

                            let response1 = JSON.parse(response1_json);
                            
                            if (response1.data && response1.data.message && response1.data.message === "User Already Exist") {
                                // User exists
                                const user = response1.data.cognito_data.Username; // Assuming the first item is the user
                                const storedEmail = localStorage.getItem('email');

                                if (user === email && storedEmail === email) {
                                    // Email matches and user exists, redirect to origin

                                    window.location.href = window.location.origin;
                                } else {

                                    localStorage.setItem("previous_state", "home")

                                    localStorage.setItem("current_state", "login")
                                    self.handleLogout(() => {
                                        // // Redirect to base URL with username after logout
                                        const baseUrl = window.location.origin;
                                        const redirectUrl = `${baseUrl}/#login_link?username=${email}`;
                                        
                                        window.location.href = redirectUrl;
                                        window.location.reload()
                                    });

                                }
                            } else {

                                localStorage.setItem("previous_state", "home")

                                localStorage.setItem("current_state", "signup")

                                // // User doesn't exist, redirect to signup
                                window.location.href = '/signup';
                            }

                        });
                        localStorage.setItem('email_transfer', email);

                        // Common operation
                        localStorage.setItem('phone_transfer', phone);
                        
                    } else {
                        
                    }
                    break;
                default:
                    functions.pushToRoute(self, "")
                    this.getDynamicContent()
                    break;
            }
        }
        else {
            functions.pushToRoute(self, "")
            this.getDynamicContent()
        }
        // var timeline11 = new TimelineMax();
        // timeline11.to('.discover_headRght', 2.5, { ease: Back.easeInOut.config(1.7), y: -500 });
        this.setState({ is_hydrated: true })


    }

    handleContinue = (subid, username) => {
        
        let self = this;
        let email = username || cookie.load("username") || localStorage.getItem("email") || "null";

        email = email ? email : "null"

        let sub_id = subid || cookie.load("auto_id") || localStorage.getItem("auto_id") || "null";
        sub_id = sub_id ? sub_id : "null";
        if (email && email != "" && email != "null" && email != null && typeof email != undefined && sub_id && sub_id != "" && sub_id != "null" && sub_id != null && typeof sub_id != undefined) {
            let finaljson = {
                email: email,
                sub_id: sub_id
            }
            functions.getUserProfile(this, finaljson, function (response_json) {
                let response = JSON.parse(response_json);
              
                if (response.catch_error) {
                    let user_action = "User got error on profile/index file componentWillMount function";
                    let body = response.message
                    functions.logUserError(user_action, body);
                    // self.setState({ loader: false, show_error_message: response.message })
                }
                else if (!response.catch_error) {

                    if (response.data.error === 1) {
                        let user_action = "User got error on profile/index file componentWillMount function";
                        let body = response.data.message
                        functions.logUserError(user_action, body);
                        // self.setState({ loader: false, show_error_message: response.data.message })
                    } else if (response.data.statusCode === 400) {
                        let user_action = "User got error on profile/index file componentWillMount function";
                        let body = response.data.message
                        functions.logUserError(user_action, body);
                        // self.setState({ loader: false, show_error_message: response.data.message })
                    }

                    else if (response.status === 200) {
                       
                            let data = response.data;
                            let cognito_data = data.cognito ? data.cognito.UserAttributes : []
                            let dynamo_data = data.dynamo.Items ? data.dynamo.Items[0] : {}
                            let relation_data = data.relation_data ? data.relation_data : {}
                            let program_data = data.program_data ? data.program_data : []
                            let winner_data = data.winner_data ? data.winner_data : [];
                            let user_status = dynamo_data.email_verified == 'true' || dynamo_data.email_verified == true ? 'Confirmed' : 'Not verified'
                            functions.storeCookie(cognito_data, dynamo_data, relation_data, program_data);

                        
                    }
                }
            })
        } else {
            Router.push('/');
        }
        // let image = 'continue_button_buildfamily_page.png'
        // functions.logUserActivity(action, image);

        let element = document.getElementById("signup_add_child")
        if (localStorage.getItem("session_type") && localStorage.getItem("session_type") == "signup") {
            // 
            element.click();
            let userdata = [];
            // add campaign id and signup status in user info
            let campaign_id = cookie.load("camp_id") ? cookie.load("camp_id") : ""
            let dynamo_userdata = { 'campaign_id': campaign_id, 'signup_status': "completed" };
            functions.updateUserData(this, userdata, dynamo_userdata);
        }

        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        // 
        let revert_page = localStorage.getItem("revert_page") ? localStorage.getItem("revert_page") : "";
        // 
        let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
        localStorage.setItem("sign_otp", "true")

        if (previous_state == "program_detail" || revert_page == "program_detail") {
            localStorage.setItem("detail_page", 'true')
            localStorage.setItem("previous_state", 'main_index')

            // functions.pushToRoute(this, "main", 'buildfamily')
            const storedProgram = localStorage.getItem('program');

if (storedProgram) {
  // Parse the program object from JSON
  const program = JSON.parse(storedProgram);

  // Check if 'perk_title' key exists
  if ('perk_title' in program) {
    Router.push('/home/perk_detail');
  } else {
    Router.push('/home/program_detail');
  }
} else {
  console.error('Program not found in localStorage');
}

        } else {
            if (perks_or_main == "perks") {
                localStorage.setItem("detail_page", 'false')
                functions.pushToRoute(this, "perks", 'buildfamily')
            }
            else {
                localStorage.setItem("detail_page", 'false')
            localStorage.setItem("previous_state", 'main_index')

                // functions.pushToRoute(this, "main", 'buildfamily')
                const storedProgram = localStorage.getItem('program');

if (storedProgram) {
  // Parse the program object from JSON
  const program = JSON.parse(storedProgram);

  // Check if 'perk_title' key exists
  if ('perk_title' in program) {
    Router.push('/home/perk_detail');
  } else {
    Router.push('/home/program_detail');
  }
} else {
  console.error('Program not found in localStorage');
}

            }
        }
    }

    fetchDataFromS3 = async () => {
        try {
            const url = "https://familyonecanadaaddress.s3.amazonaws.com/FamilyoneWebsite/familyoneWebsiteData.json"
            // Log the URL being fetched


            const response = await fetch(url);  // Use fetch to get the data from the URL
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();  // Parse the JSON data
            return data;
        } catch (error) {
            console.error('Error fetching JSON from public URL:', error);
            return null;
        }
    }

    OnPageLoad = async (data_Store) => {
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
        auto_id = auto_id ? auto_id : "";

        if (auto_id !== "") {
            functions.getLandingPage(this);
        } else {
        
        let action = "User landed on main index page";
        let image = 'main_index_page.png';
        // functions.logUserActivity(action, image);
        let fb_post = localStorage.getItem("fb_post") === "true" ? "true" : "false";
        localStorage.setItem("active_button", "0");
        let logged_in = localStorage.getItem("logged_in");

        if (logged_in === false || logged_in === 'false') {
            let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
            Object.keys(localStorage).forEach(key => !['phone_transfer', 'email_transfer'].includes(key) && localStorage.removeItem(key));
            localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content));
            cookie.remove("auto_id", { path: "/" });
        }

        this.setState({ main_loader: true });

        let pathname = window.location.hash;
        let self = this;
        

        if (pathname.length > 0 && fb_post === "false") {
            // Additional logic can be placed here if needed.
        } else {
            // Fetch data from S3 first

            // let localStoredData = localStorage.getItem("s3DataStore");
            // let data_Store = localStoredData ? JSON.parse(localStoredData) : null;

            // if (!data_Store) {
            //     // If not found in localStorage, fetch data from S3
            //     data_Store = await this.fetchDataFromS3();
            //     if (data_Store) {
            //         // Store the data in localStorage for future use
            //         localStorage.setItem("s3DataStore", JSON.stringify(data_Store));
            //     }
            // }

{console.log("self.state.all_program", self.state.all_program)}

            if (self.state.all_program || self.state.perk_array ) {
                let cognito_data = {};
                let dynamo_data = {};
                let relation_data = {};
                let program_data = self.state.all_program.sort((a, b) => a.order_number - b.order_number);
                let store_all = 'true';
                functions.storeCookie(cognito_data, dynamo_data, relation_data, program_data, store_all);
                localStorage.setItem("perk_response", JSON.stringify(self.state.perk_array));

                self.handleFixedHeader();
                self.handleSessionDetail();
                self.setState({ main_loader: false, loader: false });
                self.toggleAnimation();
            }
            else{
            
                let response_json, perk_response_json;

            // If data is not found in S3, call the original functions
            if (!response_json) {
                let finaljson_rb = { action: "get_session_program" };
                response_json = await functions.getUserProfile(this, finaljson_rb, function () { });
            }

            if (!perk_response_json) {
                perk_response_json = await functions.getPerks(self, "", function (response) { });
            }

            // Process the response_json
            if (response_json && !response_json.catch_error) {
                let all_program = response_json.data ?? [];
                self.setState({ all_program, main_loader: false, loader: false });

                let cognito_data = {};
                let dynamo_data = {};
                let relation_data = {};
                let program_data = all_program.sort((a, b) => a.order_number - b.order_number);
                let store_all = 'true';
                functions.storeCookie(cognito_data, dynamo_data, relation_data, program_data, store_all);

                // Process the program array
                let program_array_enter = [];
                let program_array_is_eligible = [];
                let program_array_not_enter = [];
                all_program.forEach((ele) => {
                    if (ele.has_enter) {
                        program_array_enter.push(ele);
                    } else {
                        if (ele.is_eligible || ele.pending_fields.length)
                            program_array_is_eligible.push(ele);
                        else
                            program_array_not_enter.push(ele);
                    }
                });

                program_array_enter.sort((a, b) => a.all_order_number - b.all_order_number);
                program_array_not_enter.sort((a, b) => a.all_order_number - b.all_order_number);
                program_array_is_eligible.sort((a, b) => a.all_order_number - b.all_order_number);
                all_program = [...program_array_is_eligible, ...program_array_not_enter, ...program_array_enter];

                let featured_program_array = all_program.filter(e => e.featured == 1);
                featured_program_array.sort((a, b) => (a.feature_order_number ?? 0) - (b.feature_order_number ?? 0));

                let contest_array = all_program.filter(e => e.program_type === "contest");
                let freebie_array = all_program.filter(e => e.program_type === "freebie");
                let survey_array = all_program.filter(e => e.program_type === "survey");
                let stories_array = all_program.filter(e => e.program_type === "story");
                let review_array = all_program.filter(e => e.program_type === "review");

                let show_freebie = freebie_array.length > 0;
                let show_giveaway = contest_array.length > 0;
                let show_survey = survey_array.length > 0;
                let show_review = review_array.length > 0;
                let show_story = stories_array.length > 0;

                let show_all_program = all_program.filter(pro => pro.show_in_all);
                self.setState({
                    show_freebie,
                    show_giveaway,
                    show_survey,
                    show_review,
                    show_story,
                    all_program: show_all_program,
                    featured_program_array,
                });

                self.handleFixedHeader();
                self.handleSessionDetail();

                $('html, body').css('overflow', 'initial');
                let session_entered_id = localStorage.getItem("session_entered_id");
                
                

                // Process perks response
                if (perk_response_json && !perk_response_json.catch_error) {
                    
                    localStorage.setItem("perk_response", JSON.stringify(perk_response_json.data));
                    let perk_array = perk_response_json.data.sort(function (a, b) {
                        return a.order_number - b.order_number
                    })
                    perk_response_json.data.forEach((element, index) => {
                        all_program.push(element)
                    })
                    self.setState({
                        perk_array: perk_array,
                        all_program
                    });
                }

                self.setState({ main_loader: false, loader: false });
                self.toggleAnimation();
            } else {
                self.setState({ main_loader: false });
            }
            localStorage.setItem("current_state", "main_home")
            }
    }
        }
    }




    allLocalStorage = () => {
        var values = [],
            keys = Object.keys(localStorage),
            i = keys.length;

        while (i--) {
            let keyname = keys[i];
            values.push({ [keyname]: localStorage.getItem(keys[i]) });
        }
        return values;
    }

    handleShowDetail = (program, main_id) => {
        
        let self = this
        
        
        // switch (program.program_type) {
        //     case "freebie":
        //     case "contest":
        //     case "review":
        //     case "story":
        //     case "survey":
        //         self.setState({ activeTab: program.program_type })
        //         break;
        //     default:
        //         this.setState({ activeTab: 'perks' })
        //         break;
        // }

        // document.getElementById(main_id).click();
        this.handleLoader()
        localStorage.setItem("entered_id", program.program_id)
        // functions.pushToRoute(self, "session_enter", "main_index")
        const formattedTitle = this.formatPerkTitle(program.program_title); // Replace spaces with underscores
                    const programId = program.program_id; // Get the program ID
                    
                    // Construct the URL with the formatted title and program ID
                    Router.push(`/${formattedTitle}_${programId}`);

        localStorage.removeItem("session_entered_id")
    }

    formatPerkTitle = (title) => {
        // Remove all special characters except whitespace
        let cleanedTitle = title.replace(/[^a-zA-Z0-9\s]/g, '');
        
        // Replace any remaining whitespace with underscores
        cleanedTitle = cleanedTitle.replace(/\s+/g, '_');
        
        return cleanedTitle;
    }

    fbcreateScript() {
        // load the sdk
        window.fbAsyncInit = this.fbAsyncInit;
        const script = document.createElement('script');
        script.src = 'https://connect.facebook.net/en_US/sdk.js';
        script.async = true;
        script.o = this.initFB;
        document.body.appendChild(script);
    }

    fbAsyncInit() {
        // init the fb sdk client
        const fb = window.FB;
        fb.init({
            appId: '1099732394769901',
            cookie: true,
            xfbml: true,
            version: 'v3.2'
        });
    }

    initFB() {
        const fb = window.FB;
    }

    toggleTab(tab) {
        let self = this;
        let action = "";
        let image = "";
        action = "User clicked on " + tab + " button on main index page"
        image = "all_button_main.png";
        let all_programs = self.state.all_program
        let program_detail = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
        let perk_array = self.state.perk_array;
        if (tab !== "all" && (all_programs.length !== ((program_detail.length) + (perk_array.length)))) {
            // self.setState({ main_loader: true, loader: true })
            let all_program = []
            let data = program_detail;
            let program_data = data ? data : []
            program_data.sort(function (a, b) {
                return a.order_number - b.order_number
            })
            program_detail.forEach((element, index) => {
                if (element.program_status === "running") {
                    if (element.after_entry_of && element.after_entry_of != "" && element.after_entry_of != "[]") {
                        let element_visibility = self.CheckProgramVisibility(element, program_data)
                        
                        
                        //  if (element_visibility == true) {
                        element.program_index = index + 1
                        element.is_visible = element_visibility ? element_visibility : false
                        switch (element.program_type) {
                            case "freebie":
                            case "contest":
                            case "survey":
                            case "story":
                            case "review":
                                element.program_index = index + 1
                                all_program.push(element)
                                break;
                        }
                    } else {
                        element.program_index = index + 1
                        element.is_visible = true
                        switch (element.program_type) {
                            case "freebie":
                            case "contest":
                            case "survey":
                            case "story":
                            case "review":
                                element.program_index = index + 1
                                all_program.push(element)
                                break;
                        }
                    }
                }
            })
            let program_array_enter = []
            let program_array_is_eligble = []
            let program_array_notenter = []
            all_program.map((ele) => {
                if (ele.has_enter) {
                    program_array_enter.push(ele)
                }
                else {
                    if (ele.is_eligible || ele.pending_fields.length)
                        program_array_is_eligble.push(ele);
                    else
                        program_array_notenter.push(ele);
                }
            })
            program_array_enter.sort(function (a, b) {
                return a.all_order_number - b.all_order_number
            })
            program_array_notenter.sort(function (a, b) {
                return a.all_order_number - b.all_order_number
            })
            program_array_is_eligble.sort(function (a, b) {
                return a.all_order_number - b.all_order_number
            })
            all_program = [...program_array_is_eligble, ...program_array_notenter, ...program_array_enter]
            // if(tab=="perks"){
            perk_array.forEach((element, index) => {
                all_program.push(element)
            })
            let fb_post = localStorage.getItem("fb_post");
            
            let session_entered_id = localStorage.getItem("session_entered_id")
            
            // self.setState({ main_loader: false, loader: false })
            // self.toggleAnimation()
            if (session_entered_id != "" && fb_post == "true") {
                var perk_index = perk_array.findIndex(p => p.auto_id == session_entered_id)
                
                if (perk_index != -1) {
                    let temp_program = perk_array[perk_index]
                    let main_id = "perks_" + temp_program.auto_id
                    self.handleShowDetail(temp_program, main_id)
                    functions.pushToRoute(self, "perks", "main_home");
                }
            }
            if (session_entered_id != "" && fb_post == "true") {
                var index = program_data.findIndex(p => p.program_id == session_entered_id)
                
                if (index != -1) {
                    let temp_program = program_data[index]
                    // localStorage.setItem("program", JSON.stringify(temp_program))
                    localStorage.setItem("program_index", index + 1)
                    localStorage.setItem("program_id", session_entered_id)
                    let main_id = temp_program.program_type + temp_program.program_id
                    self.handleShowDetail(temp_program, main_id)
                }
            }
            // }
            self.setState({ all_program })
            self.toggleAnimation()

            // self.setState({ main_loader: false, loader: false })


        }

        // functions.logUserActivity(action, image);
        if (this.state.activeTab !== tab) {
            setTimeout(function () {
                self.setState({
                    activeTab: tab
                });
            }, 50);
        }
    }

    goNext() {
        if (this.swiper) {
            
            this.swiper.slideNext()
            this.setState({ activeIndex: this.swiper.activeIndex })
        }
    }

    goPrev() {
        if (this.swiper) {
            
            this.swiper.slidePrev()
            this.setState({ activeIndex: this.swiper.activeIndex })
        }
    }

    handleSessionDetail() {
        let self = this;


        $(document).on('click', '.program_slides', function () {
            let classes = $(this).attr('class');
            let temp_classes = classes.split(" ")
            let program_array = self.state.all_program;
            var index = program_array.findIndex(p => p.program_id == temp_classes[2])
            if (index != -1) {
                let program = program_array[index];
                let program_id = program.program_id;
                let program_type = program.program_type;
                // let action = "User clicked on arrow of " + program_id + " on slider on main index page"
                // let image = 'main_slider_' + program_id + '.png';
                // functions.logUserActivity(action, image);
                // let main_id = 'session_' + program_type + program_id;
                let main_id = program_type + program_id;
                self.handleShowDetail(program, main_id)
            }
        });

        $(document).on('click', '.extragain_tabs', function () {

            // $(".extragain_tabs").animate({ scrollTop: 600 });
            var navbar = document.getElementById("navbar");
            var tab_content_start = document.getElementById("tab_content_start");
            if (navbar && tab_content_start) {
                var sticky = navbar.offsetTop;
                var sticky2 = navbar.offsetHeight;
                let sticky_tabcontent = tab_content_start.offsetTop
                
                
                
                let scrolled = $(window).scrollTop() == 0 ? $(window).scrollTop() + 2 : $(window).scrollTop() + 2
                
                if ($(window).scrollTop() >= (sticky)) {
                    
                    window.scrollTo({
                        top: sticky_tabcontent,
                        behavior: 'smooth'
                    })
                } else {
                    
                    window.scrollTo({
                        top: $(window).scrollTop() == 0 ? sticky : sticky + scrolled,
                        behavior: 'smooth'
                    })
                    setTimeout(() => {
                        window.scrollTo({
                            top: sticky_tabcontent - 10,
                            behavior: 'smooth'
                        })
                    }, 20);
                }
            }
        })

        $(document).on('click', '.ar_middle', function () {
            if (self.swiper) {
                
                // let action = "User clicked on right arrow of slider of " + self.swiper.realIndex + " index on main index page"
                // let image = 'slider_right_arrow.png'
                // functions.logUserActivity(action, image);
                self.setState({ activeIndex: self.swiper.realIndex })
            }
        });
        $(document).on('click', '.al_middle', function () {
            if (self.swiper) {
                
                // let action = "User clicked on left arrow of slider of " + self.swiper.realIndex + " index on main index page"
                // let image = 'slider_left_arrow.png'
                // functions.logUserActivity(action, image);
                self.setState({ activeIndex: self.swiper.realIndex })
            }
        });
    }

    toggleAnimation = () => {
        let self = this;
        var root = document.documentElement;
        var body = document.body;
        var pages = document.querySelectorAll(".program-page");
        var tiles = document.querySelectorAll(".program-tile");

        for (var i = 0; i < tiles.length; i++) {
            let tiles_id = tiles[i].getAttribute("id");
            let ids = '#' + tiles_id + '.program-page.hero-1';
            let pageselected = []
            pages.forEach(page => {
                if (page.getAttribute("id") == tiles_id) {
                    pageselected.push(page)
                }
            });
            if (tiles && tiles.length > 0 && pageselected && pageselected.length > 0) {
                addListeners(tiles[i], pageselected[0]);
            }
        }

        function addListeners(program_tile, page) {
            program_tile.addEventListener("click", function () {
                animateHero(program_tile, page);
            });
            page.querySelectorAll(".close_btn")[0].addEventListener("click", function () {
                animateHero(page, program_tile);
            });
        }

        function animateHero(fromHero, toHero) {
            
            // existing animation code...
            TweenLite.to(clone, 0.3, style);
            
            var clone = fromHero.cloneNode(true);
            var from = calculatePosition(fromHero);
            var to = calculatePosition(toHero);
            TweenLite.set([fromHero, toHero], { visibility: "hidden", className: "-=active" });
            TweenLite.set(clone, { position: "absolute", margin: 0, background: "#F9EAE2" });

            body.appendChild(clone);
            var style = {
                x: to.left - from.left,
                y: to.top - from.top,
                width: to.width,
                height: to.height,
                autoRound: false,
                ease: Power1.easeOut,
                onComplete: onComplete
            };

            TweenLite.set(clone, from);
            TweenLite.to(clone, 0.3, style)

            if (clone.classList.contains('program-tile', 'program_detail_logo', 'program_title01')) {
                TweenMax.fromTo(clone.querySelectorAll(".program_detail_logo")[0], 0.3, { bottom: "16px" }, { bottom: "-31px" }, 0)
                TweenMax.fromTo(clone.querySelectorAll(".program_title01 label")[0], 0.3, { 'font-size': "20px" }, { 'font-size': "25px" }, 0)
                $('html, body').css('overflow', 'hidden');
                $('.program-page-container').find('.program-page').css('min-height', window.innerHeight);
                $('.common_main_space1, .sharegain_swiper, .program-tile-container, .extragain_tabs, .fixFotrBtn').css('opacity', 0);

                // document.ontouchmove = function(e){ e.preventDefault(); }
            } else if (clone.classList.contains('program-page', 'program_detail_logo', 'freebies_iner')) {
                TweenMax.fromTo(clone.querySelectorAll(".program_detail_logo")[0], 0.3, { bottom: "-31px" }, { bottom: "16px" }, 0)
                TweenMax.fromTo(clone.querySelectorAll(".program_title01 label")[0], 0.3, { 'font-size': "25px" }, { 'font-size': "20px" }, 0)
                $('html, body').css('overflow', 'initial');
                $('.program-page-container').find('.program-page').css('min-height', window.innerHeight);
                $('.common_main_space1, .sharegain_swiper, .program-tile-container, .extragain_tabs, .fixFotrBtn').css('opacity', 1);
            }

            function onComplete() {
                TweenLite.set(toHero, { visibility: "visible", className: "+=active" });
                body.removeChild(clone);
            }
        }

        function calculatePosition(element) {
            var rect = element.getBoundingClientRect();
            var scrollTop = window.pageYOffset || root.scrollTop || body.scrollTop || 0;
            var scrollLeft = window.pageXOffset || root.scrollLeft || body.scrollLeft || 0;

            var clientTop = root.clientTop || body.clientTop || 0;
            var clientLeft = root.clientLeft || body.clientLeft || 0;

            return {
                top: Math.round(rect.top + scrollTop - clientTop),
                left: Math.round(rect.left + scrollLeft - clientLeft),
                height: rect.height,
                width: rect.width,
            };
        }
        /*End of Code of animation */
    }

    CheckProgramVisibility = (element, program_data) => {
        let after_entry_of = JSON.parse(element.after_entry_of)
        
        let program_visible = localStorage.getItem("program_visible") ? localStorage.getItem("program_visible") : ""
        if (program_visible && program_visible != "" && program_visible == element.program_id) {
            return true
        } else if (element.has_enter == 1) {
            return true
        }
        else if (element.after_entry_type == "any") {
            let element_visible = false
            
            after_entry_of.forEach((program_element, program_index) => {
                var temp_index = program_data.findIndex(p => p.program_id == program_element)
                
                if (temp_index != -1) {
                    let detail = program_data[temp_index]
                    
                    if (detail.has_enter == 1 && detail.is_eligible == 1) {
                        element_visible = true
                    }
                }
            })
            return element_visible
        } else if (element.after_entry_type == "all") {
            let element_visible = true
            
            after_entry_of.forEach((program_element, program_index) => {
                var temp_index = program_data.findIndex(p => p.program_id == program_element)
                
                if (temp_index != -1) {
                    let detail = program_data[temp_index]
                    if (detail.has_enter == 0 || detail.is_eligible == 0) {
                        element_visible = false
                    }
                }
            })
            return element_visible
        } else {
            return false
        }
    }

    handleLoader = () => {
        this.setState({ loading: true });
    }

    render() {
        const { dynamic_content } = this.state
        // const params = {
        //     loop: true,
        //     activeSlideKey: 1,
        //     effect: 'coverflow',
        //     grabCursor: true,
        //     centeredSlides: true,
        //     slidesPerView: 2,
        //     coverflowEffect: {
        //         rotate: 40,
        //         stretch: 0,
        //         depth: 100,
        //         modifier: 1,
        //         slideShadows: false,
        //     },
        //     navigation: {
        //         nextEl: '.swiper-button-next',
        //         prevEl: '.swiper-button-prev'
        //     },
        //     pagination: {
        //         el: '.swiper-pagination',
        //         type: 'bullets',
        //         clickable: false
        //     },
        //     renderPrevButton: () => <button className="swiper-button-prev al_middle">Prev</button>,
        //     renderNextButton: () => <button className="swiper-button-next ar_middle">Next</button>
        // }
        const params = {
            loop: true, // Enable loop
            //effect: 'coverflow',
            grabCursor: true, // Enable grab cursor
            centeredSlides: true,
            slidesPerView: 3,
            //spaceBetween: '5%',
            // coverflowEffect: {
            //     rotate: 50,
            //     stretch: 0,
            //     depth: 0,
            //     modifier: 1,
            //     slideShadows: true,

            // rotate: 0,
            // stretch: 0,
            // depth: 1457.1429, // Set the depth for the 3D effect
            // modifier: 4,
            // slideShadows: true,

            //},
            pagination: {
              el: '.swiper-pagination',
              clickable: true, // Make pagination clickable
            },
            navigation: {
              nextEl: '.swiper-button-next', // Define the next button element
              prevEl: '.swiper-button-prev', // Define the previous button element
            },
            // modules: [EffectCoverflow, Pagination, Navigation], 
            modules: [Pagination, Navigation], 
            className: 'mySwiper'
        }
        return (
            <div className="container">
                <div className="row">
                    <div id="login_complete" ></div>
                    <div className="common_mainAddOn">
                        <div className="common_main common_main_rmove">

                           {this.state.loading && (
                             <div className='loaderCstm'>
                                <LottieLoader className="loader_class" />
                             </div>
                            )}

                            {/* {this.state.main_loader &&
                                <center>
                                    <MyLoader />
                                </center>
                            } */}

                            {/* {!this.state.main_loader && */}

                                <div>
                                    
                                    {/* {!this.state.loader &&
                                        <center>
                                            <LottieLoader />
                                        </center>
                                    } */}

                                    {this.state.featured_program_array && this.state.featured_program_array.length > 0 &&
                                        <div className='addSliderBg'>
                                            <div className="common_main_space1 addPurpalLineBg">
                                                {/* Head bar */}
                                                <div className="discover_head">
                                                    {/* <div className="discover_headLeft"><label>{dynamic_content.static_text.main && dynamic_content.static_text.main.discover ? dynamic_content.static_text.main.discover.en : "Discover"}</label></div> */}
                                                    <div className="discover_headLeft">
                                                        <img className="discover_image" src="https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/990369cd-1278-4667-4b38-d6048a335900/public" alt="FamilyOne" title="FamilyOne" ></img>
                                                    </div>
                                                </div>
                                                {/* Head bar */}
                                            </div>
                                            <div className="sharegain_swiper 141414">
                                                <Swiper {...params}
                                                  ref={node => { if (node) this.swiper = node.swiper }}>
                                                    {this.state.featured_program_array && this.state.featured_program_array.length > 0 && this.state.featured_program_array.map((program, index) => (
                                                        <SwiperSlide style={{height: 186, width: 186}} key={"featured_program_array" + index} >
                                                            <div style={{position: "relative", height: 186, width: 186}} className={"program_slides slides " + program.program_id}>

                                                            {
                                                                (program.featured_image || program.program_image) ? (
                                                                  <img
                                                                    className="slideImg"
                                                                    height={186}
                                                                    width={186}
                                                                    src={
                                                                        (program.featured_image
                                                                          ? (url.cloudflare_image_path) + program.featured_image
                                                                          : url.cloudflare_image_path + program.program_image) + url.varient}
                                                                    alt='FamilyOne' // Fallback alt text in case `program.feature_title` is missing
                                                                    title='FamilyOne' // Fallback title
                                                                  ></img>
                                                                ) : (
                                                                  <img src="/images/whiteBg.jpg" alt="FamilyOne" title="FamilyOne" height={186} width={186} ></img>
                                                                )
                                                              }
                                                              
                                                                {program.program_type == "contest" &&
                                                                    <div className="nextslides">
                                                                        <div className="discover_icon_div discover_icon_divAdd"><img src="https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/61468e1f-2d75-4586-5aeb-ade224d31900/public" alt="Canadian family community" title="Canadian family community" ></img></div>
                                                                    </div>
                                                                }
                                                                {program.program_type == "survey" &&
                                                                    <div className="nextslides">
                                                                        <div className="discover_icon_div discover_icon_divAdd">
                                                                            <img src="/images/Surveys-active-Icon.svg" alt="Parenting resources Canada" title="Parenting resources Canada" ></img>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {program.program_type == "freebie" &&
                                                                    <div className="nextslides">
                                                                        <div className="discover_icon_div discover_icon_divAdd">
                                                                            <img src="https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/e76e1468-7720-4b3a-b5d7-23cc9dac9400/public" alt="Family membership Canada" title="Family membership Canada" ></img>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {program.program_type == "story" &&
                                                                    <div className="nextslides">
                                                                        <div className="discover_icon_div discover_icon_divAdd">
                                                                            <img src="/images/Stories-active-Icon.svg" alt="Freebies for families" title="Freebies for families" ></img>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {program.program_type == "review" &&
                                                                    <div className="nextslides"> <div className="discover_icon_div" ><img src={url.family_one_image_url + 'white-icon-REVIEW_128.png'} alt="Family giveaways" title="Family giveaways" ></img> </div></div>
                                                                }
                                                            </div>
                                                            {/* {this.swiper && this.swiper.realIndex && this.swiper.realIndex == index && */}
                                                            {/* <div className="gain_extra">
                                                            <h1>{ReactHtmlParser((program.feature_title && program.feature_title.trim() !== "") ? program.feature_title : program.program_title)} </h1>
                                                        </div> */}

                                                            <div className="gain_extra" style={{ cursor: "default !important" }}>
                                                                {React.createElement(`h${index + 1}`, null,
                                                                    ReactHtmlParser((program.feature_title && program.feature_title.trim() !== "") ? program.feature_title : program.program_title)
                                                                )}
                                                            </div>

                                                            {/* } */}

                                                        </SwiperSlide>
                                                    ))}
                                                    {/* Add pagination element */}
                                                    <div className="swiper-pagination"></div>
                                                    {/* Add navigation buttons */}
                                                    <div className="swiper-button-next"></div>
                                                    <div className="swiper-button-prev"></div>
                                                </Swiper>
                                            </div>
                                            {/* ))} */}
                                            <img src="https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/d7e6ac72-d6e0-4b76-7fc0-cf762a16a700/public" alt="Parenting tips" title="Parenting tips" className='outerDotSliderLogin' ></img>
                                        </div>
                                    }

                                    <div className="common_main_space" id="tab_content_start">

                                        {/*Tabs content */}
                                        <div id="navbar" className="extragain_tabs fix_main">

                                            <Nav tabs>
                                                {this.state.all_program && this.state.all_program.length > 0 && (
                                                    <NavItem>
                                                        <NavLink href="#" onClick={() => { this.toggleTab('all'); }}>
                                                            <div className='cmnTabPrgrm allTabClr'>
                                                                {this.state.activeTab === 'all' ? (
                                                                    <img src="https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/0f6d2dfb-5972-4fce-c825-92d02311d300/public" alt="Family discounts" title="Family discounts" ></img>
                                                                ) : (
                                                                    <img src="https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/42260c2a-ec14-42eb-29c4-77d2e8b36000/public" alt="Family discounts" title="Family discounts" ></img>
                                                                )}
                                                                <label>All</label>
                                                            </div>
                                                            <span className="nav_dot"></span>
                                                        </NavLink>
                                                    </NavItem>
                                                )}
                                                {this.state.show_freebie && (
                                                    <NavItem>
                                                        <NavLink href="#" onClick={() => { this.toggleTab('freebie'); }}>
                                                            <div className='cmnTabPrgrm freebiesTabClr'>
                                                                {this.state.activeTab === 'freebie' ? (
                                                                    <img src="https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/e76e1468-7720-4b3a-b5d7-23cc9dac9400/public" alt="Baby product giveaways" title="Baby product giveaways" ></img>
                                                                ) : (
                                                                    <img src="https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/3be77af5-6b2e-44d5-aae3-f2b3d1319a00/public" alt="Baby product giveaways" title="Baby product giveaways" ></img>
                                                                )}
                                                                <label>Freebies</label>
                                                            </div>
                                                            <span className="nav_dot"></span>
                                                        </NavLink>
                                                    </NavItem>
                                                )}
                                                {this.state.show_giveaway && (
                                                    <NavItem>
                                                        <NavLink href="#" onClick={() => { this.toggleTab('contest'); }}>
                                                            <div className='cmnTabPrgrm contestTabClr'>
                                                                {this.state.activeTab === 'contest' ? (
                                                                    <img src="https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/61468e1f-2d75-4586-5aeb-ade224d31900/public" alt="Family product reviews" title="Family product reviews" ></img>
                                                                ) : (
                                                                    <img src="https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/908b4cde-5c4d-49cc-f695-8e6ce4878a00/public" alt="Family product reviews" title="Family product reviews" ></img>
                                                                )}
                                                                <label>Giveaways</label>
                                                            </div>
                                                            <span className="nav_dot"></span>
                                                        </NavLink>
                                                    </NavItem>
                                                )}
                                                {this.state.show_survey && (
                                                    <NavItem>
                                                        <NavLink href="#" onClick={() => { this.toggleTab('survey'); }}>
                                                            <div className='cmnTabPrgrm surveyTabClr'>
                                                                {this.state.activeTab === 'survey' ? (
                                                                    <img src="/images/Surveys-active-Icon.svg" alt="Parenting support" title="Parenting support" ></img>
                                                                ) : (
                                                                    <img src="/images/Surveys-Icon.svg" alt="Parenting support" title="Parenting support" ></img>
                                                                )}
                                                                <label>Surveys</label>
                                                            </div>
                                                            <span className="nav_dot"></span>
                                                        </NavLink>
                                                    </NavItem>
                                                )}
                                                {this.state.perk_array && this.state.perk_array.length > 0 && (
                                                    <NavItem>
                                                        <NavLink href="#" onClick={() => { this.toggleTab('perks'); }}>
                                                            <div className='cmnTabPrgrm perksTabClr'>
                                                                {this.state.activeTab === 'perks' ? (
                                                                    <img src="https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/04fca737-c4b2-4cb2-01f1-86bd49fcaf00/public" alt="Family events Canada" title="Family events Canada" ></img>
                                                                ) : (
                                                                    <img src="https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/f324dbb2-871d-4c07-203e-275a781a8400/public" alt="Family events Canada" title="Family events Canada" ></img>
                                                                )}
                                                                <label>Perks</label>
                                                            </div>
                                                            <span className="nav_dot"></span>
                                                        </NavLink>
                                                    </NavItem>
                                                )}
                                                {this.state.show_review && (
                                                    <NavItem>
                                                        <NavLink href="#" onClick={() => { this.toggleTab('review'); }}>
                                                            {dynamic_content ? dynamic_content.static_text.landing_page.reviews.en : "Reviews"}
                                                            <span className="nav_dot"></span>
                                                        </NavLink>
                                                    </NavItem>
                                                )}
                                                {this.state.show_story && (
                                                    <NavItem>
                                                        <NavLink href="#" onClick={() => { this.toggleTab('story'); }}>
                                                            <div className='cmnTabPrgrm storyTabClr'>
                                                                {this.state.activeTab === 'story' ? (
                                                                    <img src="https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/a912e664-f8f2-472c-fdba-95fe5de6fb00/public" alt="Child education resources" title="Child education resources" ></img>
                                                                ) : (
                                                                    <img src="https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/c7ab89e0-bffd-427f-13a6-f4c5c282c400/public" alt="Child education resources" title="Child education resources" ></img>
                                                                )}
                                                                <label>Stories</label>
                                                            </div>
                                                            <span className="nav_dot"></span>
                                                        </NavLink>
                                                    </NavItem>
                                                )}
                                            </Nav>
                                        </div>

                                        <TabContent activeTab="1">
                                            {/* Freebies Tab Content */}
                                            <TabPane tabId="1">
                                                {this.state.loader &&
                                                   <div className="loaderCenter"><center><LottieLoader /></center></div>
                                                }
        <div className="program-tile-container">
    {[...(this.props.all_prog || []), ...(this.props.perk_array || [])].length > 0 ? (
        [...(this.props.all_prog || []), ...(this.props.perk_array || [])].map((program) => (
            <Programtile
                usedIn="home"
                history={this.props.history}
                activeTab={this.state.activeTab}
                key={`all_session_${program.program_id || program.auto_id}`}
                props={this.props}
                program={program}
            />
        ))
    ) : (
        <ContentLoader>
            <rect x="80" y="0" width="200" height="100" rx="5" ry="5" />
        </ContentLoader>
    )}
</div>



                                                {/* <div className="program-page-container 1">
                                                {!this.state.loader && this.state.all_program && this.state.all_program.length > 0 && this.state.all_program.map((program, index) => (
                                                    <div key={program.program_id ? "all_session_detail_" + program.program_id : "all_session_detail_" + program.auto_id}>
                                                        {program.program_id && program.program_type !== 'story' ?
                                                            <SessionEnter props={this.props} program={program} />
                                                            :
                                                            program.program_type === 'story' ?
                                                                <StoryDetail key={program.program_id ? "story_session_enter_" + program.program_id : "story_session_enter_" + program.auto_id} props={this.props} program={program} auto_id={"no"} /> :
                                                                <PerksDetailAll props={this.props} auto_id={this.state.auto_id} detail={program} active={this.state.active} />
                                                        }
                                                    </div>
                                                ))}
                                            </div> */}
                                            </TabPane>
                                        </TabContent>
                                        {/* End of Tabs content*/}
                                    </div>
                                    {/* <div className="fixFotrBtn" onClick={() => {
                                    let action = "User clicked on Log In / Sign Up button on main index page"
                                    let image = "login_signup_main.png"
                                    functions.logUserActivity(action, image);
                                    functions.pushToRoute(this, 'home', 'main_home')
                                }}><a>{dynamic_content ? dynamic_content.static_text.landing_page.log_in_sign_up.en : "Log In / Sign Up"}</a>
                                </div> */}
                                    {/* <Footer props={this.props} /> */}
                                </div>

                            {/* } */}

                            {/* <div className="fixFotrBtn" onClick={() => {
                                let action = "User clicked on Log In / Sign Up button on main index page"
                                let image = "login_signup_main.png"

                                functions.logUserActivity(action, image);
                                this.props.router.push('/home/home');
                                functions.pushToRoute(this, 'home', 'main_home')
                            }}><a>{dynamic_content ? dynamic_content.static_text.landing_page.log_in_sign_up.en : "Log In / Sign Up"}</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
// export default Homemain;

const mapStateToProps = ({ program, child_invitation }) => {

    const { program_detail, program_list, all_program } = program;
    const { child_invitation_switch } = child_invitation
    return { program_detail, program_list, all_program, child_invitation_switch }
}
const mapDispatchToProps = dispatch => {
    return {
        handleProgramDetail: (program) => dispatch({ type: 'ADD_PROGRAM_DETAIL', payload: program }),
        handleprogramList: (program) => dispatch({ type: 'ADD_PROGRAM_LIST', payload: program }),
        handleAllprogramList: (all_program) => dispatch({ type: 'ADD_ALL_PROGRAM_LIST', payload: all_program })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Homemain));




export async function getStaticProps() {
    try {
        const url = 'https://familyonecanadaaddress.s3.amazonaws.com/FamilyoneWebsite/familyoneWebsiteData.json';
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Parse the fetched JSON data
        const data_Store = await response.json();

        // Initialize the response objects
        let response_json = { catch_error: true, data: [] };
        let perk_response_json = { catch_error: true, data: [] };

        // Ensure program_list and story_list are present, and merge them
        if (Array.isArray(data_Store?.program_list) && Array.isArray(data_Store?.story_list)) {
            response_json = {
                catch_error: false,
                data: [...data_Store.program_list, ...data_Store.story_list],
            };
        }

        // Handle perk_list if it exists
        if (data_Store?.perk_list) {
            perk_response_json = {
                catch_error: false,
                data: data_Store.perk_list,
            };
        }

        // Initialize all_program variable if response_json is valid
        let all_program = [];
        if (response_json && !response_json.catch_error) {
            all_program = response_json.data ?? [];
          

            // Process the program array into different categories
            let program_array_enter = [];
            let program_array_is_eligible = [];
            let program_array_not_enter = [];
            all_program.forEach((ele) => {
                if (ele.has_enter) {
                    program_array_enter.push(ele);
                } else {
                    if (ele.is_eligible || ele.pending_fields.length) {
                        program_array_is_eligible.push(ele);
                    } else {
                        program_array_not_enter.push(ele);
                    }
                }
            });

            // Sorting the arrays by order_number
            program_array_enter.sort((a, b) => a.all_order_number - b.all_order_number);
            program_array_not_enter.sort((a, b) => a.all_order_number - b.all_order_number);
            program_array_is_eligible.sort((a, b) => a.all_order_number - b.all_order_number);

            // Merging the sorted arrays into final all_program
            all_program = [...program_array_is_eligible, ...program_array_not_enter, ...program_array_enter];
            // Separate featured programs
            let featured_program_array = all_program.filter(e => e.featured === 1);
            featured_program_array.sort((a, b) => (a.feature_order_number ?? 0) - (b.feature_order_number ?? 0));

            // Filter by different program types
            let contest_array = all_program.filter(e => e.program_type === "contest");
            let freebie_array = all_program.filter(e => e.program_type === "freebie");
            let survey_array = all_program.filter(e => e.program_type === "survey");
            let stories_array = all_program.filter(e => e.program_type === "story");
            let review_array = all_program.filter(e => e.program_type === "review");

            // Boolean flags to determine if there are any items in these categories
            let show_freebie = freebie_array.length > 0;
            let show_giveaway = contest_array.length > 0;
            let show_survey = survey_array.length > 0;
            let show_review = review_array.length > 0;
            let show_story = stories_array.length > 0;

            // Filter programs that should show in the "all" category
            let show_all_program = all_program.filter(pro => pro.show_in_all);

            // Process the perks response
            let perk_array = [];
            if (perk_response_json && !perk_response_json.catch_error) {
                perk_array = perk_response_json.data.sort((a, b) => a.order_number - b.order_number);

                // Add perk items to all_program
                // perk_response_json.data.forEach((element) => {
                //     all_program.push(element);
                // });
            }



            // Return the necessary props
            return {
                props: {
                    show_freebie,
                    show_giveaway,
                    show_survey,
                    show_review,
                    show_story,
                    all_prog: all_program,
                    featured_program_array,
                    perk_array,  // Include the perk data
                },
            };
        }

        // Fallback if response_json has errors
        return {
            props: {
                show_freebie: false,
                show_giveaway: false,
                show_survey: false,
                show_review: false,
                show_story: false,
                all_program: [],
                featured_program_array: [],
                perk_array: [],
            },
        };
    } catch (error) {
        // Log error and return fallback props
        console.error('Error fetching data:', error);
        return {
            props: {
                show_freebie: false,
                show_giveaway: false,
                show_survey: false,
                show_review: false,
                show_story: false,
                all_program: [],
                featured_program_array: [],
                perk_array: [],
            },
        };
    }
}
